
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import HlaInput from '@/components/shared/HlaInput.vue';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { RootState, GenericCodeValue, ObjectId } from '@/store/types';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import HlaInputGroup from '@/components/shared/HlaInputGroup.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Laboratory, HlaTestKitDefault } from '@/store/laboratories/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdLookup } from '@/store/validations/types';
import { Recipient, RecipientProfileDetails } from '@/store/recipients/types';
import { LabHLAAntibody, HlaAntibodyData, HlaAntibodyTestingMethod, HlaSabClass, HlaSabCategory, HLAAntibodiesForm, HlaAntibodyDataForm } from '@/store/labs/types';

// Form schema
export interface HlaAntibodyRow {
  _id?: { $oid: string };
  sampleCode?: string;
  sampleDrawDate?: string;
  laboratory?: string;
  cpra1?: string;
  cpra2?: string;
  combinedCpra?: string;
}

@Component({
  components: {
    HlaInput,
    TextInput,
    DateInput,
    SubSection,
    CardSection,
    SelectInput,
    SaveToolbar,
    NumberInput,
    TextAreaInput,
    HlaInputGroup,
  }
})
export default class HlaAntibodiesTest extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.labs.hlaAntibodies) private hlaAntibodies!: LabHLAAntibody[];
  @State(state => state.labs.selectedHlaAntibodies) private selectedHlaAntibodies!: LabHLAAntibody;
  @State(state => state.pageState.currentPage.hlaAntibodies) editState!: HLAAntibodiesForm;
  @State(state => state.laboratories.hla) private hlaLaboratoryLookup!: Laboratory[];
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.lookups.hla_testing_method) private  hlaTestingMethodLookup!: any;
  @State(state => state.lookups.laboratory_hla_antibody_test_kits) private hlaAntibodyTestKitLookup!: HlaAntibodyTestKit[];

  // Getters
  @Getter('newHlaAntibodies', { namespace: 'labs' }) newHLAAntibodies!: (userLab?: string) => LabHLAAntibody;
  @Getter('standardizeAntibodies', { namespace: 'labs' }) standardizeAntibodies!: (antibodies?: string[]) => string[];
  @Getter('lookupHlaAntibodyTestingKit', { namespace: 'lookups' }) lookupHlaAntibodyTestingKit!: (code: string|null) => HlaAntibodyTestKit|null;
  @Getter('impliedTestingMethodDefault', { namespace: 'labs' }) impliedTestingMethodDefault!: (hlaAntibodiesTest: LabHLAAntibody) => HlaAntibodyTestingMethod|undefined;
  @Getter('hlaTestingKitClass1Options', { namespace: 'lookups' }) hlaTestingKitClass1Options!: GenericCodeValue[];
  @Getter('hlaTestingKitClass2Options', { namespace: 'lookups' }) hlaTestingKitClass2Options!: GenericCodeValue[];
  @Getter('mostRecentHlaTestingKitClass1Code', { namespace: 'lookups' }) mostRecentHlaTestingKitClass1Code!: string;
  @Getter('mostRecentHlaTestingKitClass2Code', { namespace: 'lookups' }) mostRecentHlaTestingKitClass2Code!: string;
  @Getter('defaultHlaAcceptableClass1', { namespace: 'laboratories' }) defaultHlaAcceptableClass1!: (laboratory?: Laboratory) => string[];
  @Getter('defaultHlaAcceptableClass2', { namespace: 'laboratories' }) defaultHlaAcceptableClass2!: (laboratory?: Laboratory) => string[];
  @Getter('buildHLAAntibodiesForm', { namespace: 'labs' }) buildHLAAntibodiesForm!: (hlaAntibodiesData: LabHLAAntibody) => HLAAntibodiesForm;
  @Getter('buildAlleleSpecific', { namespace: 'labs' }) buildAlleleSpecific!: (testedAntibodies: string[], inputAntibodies: string[]) => string[];
  @Getter('buildAlphaBetaSpecific', { namespace: 'labs' }) buildAlphaBetaSpecific!: (testedAntibodies: string[], inputAntibodies: string[]) => string[];
  @Getter('buildAcceptable', { namespace: 'labs' }) buildAcceptable!: (testedAntibodies: string[], inputAntibodies: string[], alleleSpecific: string[], alphaBeta: string[], possibleAlleleSpecific: string[]) => string[];
  @Getter('getAllUserLaboratoryIds', { namespace: 'users' }) getAllUserLaboratoryIds!: string[];

  @Prop({ default: false }) canSave!: boolean;

  // Local declarations of enumerations (allows them to be used in the template above)
  private sabCategory = HlaSabCategory;
  private sabClass = HlaSabClass;

  /**
   * Gets the full Laboratory document corresponding to the selected HLA Laboratory.
   *
   * @returns {Laboratory|undefined} selected Laboratory, or undefined if there is none
   */
  get selectedHlaLaboratory(): Laboratory|undefined {
    if (!this.editState || !this.editState.hla_lab || !this.hlaLaboratoryLookup) {
      return undefined;
    }
    return this.hlaLaboratoryLookup.find((laboratory: Laboratory) => {
      return laboratory.lab_code === this.editState.hla_lab;
    });
  }

  /**
   * Gets table row data for recipient HLA Antibodies Tests, sorted by Sample Date in descending order.
   *
   * Tests with no Sample Date are sorted as if they were sampled today.
   *
   * @returns {HlaAntibodyRow[]} HLA Antibodies Test rows sorted by Sample Date
   */
  get hlaAntibodyRows(): HlaAntibodyRow[] {
    const result = this.hlaAntibodies.map((lab: LabHLAAntibody) => {
      return {
        _id: lab._id,
        sampleCode: lab.test_code || '-',
        sampleDrawDate: this.parseDisplayDateUi(lab.sample_date) || '-',
        laboratory: lab.lab_code || '-',
        cpra1: lab.cpra_class1 == null ? '-' : lab.cpra_class1.toString(),
        cpra2: lab.cpra_class2 == null ? '-' : lab.cpra_class2.toString(),
        combinedCpra: lab.cpra == null ? '-' : lab.cpra.toString(),
      };
    });
    return result;
  }

  /**
   * Gets table configuration for HLA Antibodies Tests.
   *
   * @return {TableConfig} HLA Antibodies Tests table configration
   */
  get hlaAntibodiesTableConfig(): TableConfig {
    return {
      data: this.hlaAntibodyRows,
      columns: [
        { label: this.$t('sample_code').toString(), field: 'sampleCode', width: '16.67%' },
        { label: this.$t('sample_draw_date').toString(), field: 'sampleDrawDate', width: '16.67%' },
        { label: this.$t('laboratory').toString(), field: 'laboratory', width: '16.67%' },
        { label: this.$t('cpra_pra_class_i').toString(), field: 'cpra1', width: '16.67%' },
        { label: this.$t('cpra_pra_class_ii').toString(), field: 'cpra2', width: '16.67%' },
        { label: this.$t('combined_cpra').toString(), field: 'combinedCpra', width: '16.67%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSave,
      createText: this.$t('create_hla_antibodies_test').toString(),
      pagination: true
    };
  }

  // Check which Class I SAB Testing Method has been selected if any
  get selectedTestingMethodClass1(): number|null {
    if (!this.editState) return null;
    
    return this.editState.testing_method_class1 || null;
  }

  // Check which Class II SAB Testing Method has been selected if any
  get selectedTestingMethodClass2(): number|null {
    if (!this.editState) return null;
    
    return this.editState.testing_method_class2 || null;
  }

  // Check which Class I SAB Testing Kit has been selected if any
  get selectedTestKitClass1(): HlaAntibodyTestKit|null {
    if (!this.editState) return null;
    
    const testingKit = this.editState.testing_kit_class1 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Check which Class II SAB Testing Kit has been selected if any
  get selectedTestKitClass2(): HlaAntibodyTestKit|null {
    if (!this.editState) return null;
    
    const testingKit = this.editState.testing_kit_class2 || null;
    return this.lookupHlaAntibodyTestingKit(testingKit);
  }

  // Conditions for showing form areas

  /**
   * Checks if the form area for non-storage HLA Antibodies Tests should be shown
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showNonStorage(): boolean {
    if (!this.editState) return false;

    const testingMethod: number|null = this.editState.testing_method_default || null;
    return testingMethod != null && testingMethod !== HlaAntibodyTestingMethod.Storage;
  }

  /**
   * Checks if the form area for Class 1 ID/PRA data entry should be shown
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showPraClass1(): boolean {
    if (!this.editState) return false;

    const testingMethod: number|null = this.editState.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  /**
   * Checks if the form area for Class 2 ID/PRA data entry should be shown
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showPraClass2(): boolean {
    if (!this.editState) return false;

    const testingMethod: number|null = this.editState.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Idpra;
  }

  /**
   * Show Class I SAB tags and calculated cPRA Class I field only if Class I Testing Method is set to SAB
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showSabClass1(): boolean {
    if (!this.editState) return false;

    const testingMethod: number|null = this.editState.testing_method_class1 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  /**
   * Show Class II SAB tags and calculated cPRA Class II field only if Class II Testing Method is set to SAB
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showSabClass2(): boolean {
    if (!this.editState) return false;

    const testingMethod: number|null = this.editState.testing_method_class2 || null;
    return testingMethod != null && testingMethod === HlaAntibodyTestingMethod.Sab;
  }

  /**
   * Show calculated Combined cPRA field only if both Class I Testing Method and Class II Testing Method are
   * PRA or SAB. i.e. If either of the Testing Methods are storage or blank, there is no Combined cPRA.
   *
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showCombinedCpra(): boolean {
    return (this.showSabClass1 || this.showPraClass1) && (this.showSabClass2 || this.showPraClass2);
  }

  // Display Class 1 legacy data?
  get showClass1PossibleAlleleSpecific(): boolean {
    if (!this.editState) return false;

    const values = this.editState?.antibodies?.class1_possible_allele_specific || [];
    return values.length > 0;
  }

  // Display Class 2 legacy data?
  get showClass2PossibleAlleleSpecific(): boolean {
    if (!this.editState) return false;

    const values = this.editState?.antibodies?.class2_possible_allele_specific || [];
    return values.length > 0;
  }

  // Event handlers

  /**
   * Loads all HLA Antibodies Tests for the selected recipient.
   *
   * Called after the Card Section has finished loading any relevant lookups.
   *
   * @listens antibodies#loaded
   */
  private loaded(): void {
    this.loadHlaAntibodiesTests();
  }

  private clearClass1SabAntibodies(): void {
    if (!this.editState || !this.editState.antibodies) {
      return;
    }
    // Fetch both Class I and Class II antibodies
    const antibodies = this.editState.antibodies;
    // Set all Class I antibody fields to empty arrays
    Object.assign(antibodies, {
      class1_acceptable: [],
      class1_allele_specific: [],
      class1_alpha_beta_specific: [],
      class1_unacceptable: [],
      class1_unacceptable_allele_specific: [],
      class1_unacceptable_alpha_beta: [],
      class1_indeterminate: [],
      class1_indeterminate_allele_specific: [],
      class1_indeterminate_alpha_beta: [],
      // Also set epitopes to empty arrays, as current data schema assumes all epitopes are class I
      epitopes_acceptable: [],
      epitopes_unacceptable: [],
      epitopes_indeterminate: [],
      epitopes_untested: [],
    });
    // Update state with a single mutation
    Vue.set(this.editState, 'antibodies', antibodies);
  }

  private clearClass2SabAntibodies(): void {
    if (!this.editState || !this.editState.antibodies) {
      return;
    }
    // Fetch both Class I and Class II antibodies
    const antibodies = this.editState.antibodies;
    // Set all Class II antibody fields to empty arrays
    Object.assign(antibodies, {
      class2_acceptable: [],
      class2_allele_specific: [],
      class2_alpha_beta_specific: [],
      class2_unacceptable: [],
      class2_unacceptable_allele_specific: [],
      class2_unacceptable_alpha_beta: [],
      class2_indeterminate: [],
      class2_indeterminate_allele_specific: [],
      class2_indeterminate_alpha_beta: [],
      // No need to clear epitopes, as current data schema assumes all epitopes are class I
    });
    // Update state with a single mutation
    Vue.set(this.editState, 'antibodies', antibodies);
  }

  private clearAllSabAntibodies(): void {
    this.clearClass1SabAntibodies();
    this.clearClass2SabAntibodies();
  }

  /**
   * Sets both class-specific Testing Methods based on the selected Testing Method Default.
   *
   * @listens testing_method_default#changed
   */
  private changedTestingMethodDefault(testingMethod: any): void {
    // Fetch current form edit state
    const editState = this.editState;
    // When overall Testing Method Default changes, set both class-specific Testing Methods to the same value
    Object.assign(editState, {
      testing_method_class1: testingMethod,
      testing_method_class2: testingMethod,
    });
    // Storage - Clear all ID/PRA and SAB fields
    if (!this.showNonStorage) {
      Object.assign(editState, {
        sample_tested_date: null,
        cpra_class1: null,
        cpra_class2: null,
        combined_cpra: null,
      });
    } else if (!editState.sample_tested_date) {
      // if sample tested date is null and testing method is not storage
      // then assign current date to sample tested date
      Object.assign(editState, {
        sample_tested_date: this.currentDateUi(),
      });
    }
    // on testing default is Sab, check test_kit_class, if null assign mostRecent testing kit code
    if (testingMethod == HlaAntibodyTestingMethod.Sab.toString()) {
      if (!editState.testing_kit_class1) {
        Object.assign(editState, {
          testing_kit_class1: this.mostRecentHlaTestingKitClass1Code,
        });
      }
      if (!editState.testing_kit_class2) {
        Object.assign(editState, {
          testing_kit_class2: this.mostRecentHlaTestingKitClass2Code,
        });
      }
    }

    // Update standard form fields with a single mutation
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodies',
      value: editState,
    });
    // Clear or update SAB antibody data arrays in form state as needed
    if (!this.showSabClass1 && !this.showSabClass2) {
      this.clearAllSabAntibodies();
    } else {
      this.updateSabAntibodiesClass1();
      this.updateSabAntibodiesClass2();
    }
    this.resetInvalidAntibodies();
  }

  // Clear unrelated fields when Class I testing methods changes
  private onTestingMethodClass1Change(testingMethod: any): void {
    // Fetch current form edit state
    const editState = this.editState;
    // When Class I is set to the same method as Class II, set overall Testing Method Default as well
    const otherTestingMethod = editState.testing_method_class2;
    if (testingMethod == otherTestingMethod) {
      editState.testing_method_default = testingMethod;
    }
    // Storage - Clear PRA Class I
    if (testingMethod !== HlaAntibodyTestingMethod.Idpra.toString()) {
      Object.assign(editState, {
        cpra_class1: null,
        combined_cpra: null,
      });
    }
    // on update to Sab, check test_kit_class if null assign mostRecent testing kit code
    if (testingMethod == HlaAntibodyTestingMethod.Sab.toString()) {
      if (!editState.testing_kit_class1) {
        Object.assign(editState, {
          testing_kit_class1: this.mostRecentHlaTestingKitClass1Code,
        });
      }
    }
    // Update standard form fields with a single mutation
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodies',
      value: editState,
    });
    // Clear or update SAB antibody data arrays in form state as needed
    if (!this.showSabClass1) {
      this.clearClass1SabAntibodies();
    } else {
      this.updateSabAntibodiesClass1();
    }
    this.resetInvalidAntibodies();
  }

  // Clear unrelated fields when Class II testing methods changes
  private onTestingMethodClass2Change(testingMethod: any): void {
    // Fetch current form edit state
    const editState = this.editState;
    // When Class II is set to the same method as Class I, set overall Testing Method Default as well
    const otherTestingMethod = this.editState.testing_method_class1;
    if (testingMethod == otherTestingMethod) {
      editState.testing_method_default = testingMethod;
    }
    // Storage - Clear PRA Class II
    if (testingMethod !== HlaAntibodyTestingMethod.Idpra.toString()) {
      Object.assign(editState, {
        cpra_class2: null,
        combined_cpra: null,
      });
    }
    // on update to Sab, check test_kit_class if null assign mostRecent testing kit code
    if (testingMethod == HlaAntibodyTestingMethod.Sab.toString()) {
      if (!editState.testing_kit_class2) {
        Object.assign(editState, {
          testing_kit_class2: this.mostRecentHlaTestingKitClass2Code,
        });
      }
    }
    // Update standard form fields with a single mutation
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodies',
      value: editState,
    });
    // Clear or update SAB antibody data arrays in form state as needed
    if (!this.showSabClass2) {
      this.clearClass2SabAntibodies();
    } else {
      this.updateSabAntibodiesClass2();
    }
    this.resetInvalidAntibodies();
  }

  // Update SAB antibody data arrays when Class I testing kits change
  private onTestingKitClass1Change(testingKit: any): void {
    if (!this.editState) return;

    if (!this.showSabClass1) {
      this.clearClass1SabAntibodies();
    } else {
      this.updateSabAntibodiesClass1();
    }
    this.resetInvalidAntibodies();
  }

  // Update SAB antibody data arrays when Class II testing kits change
  private onTestingKitClass2Change(testingKit: any): void {
    if (!this.editState) return;

    if (!this.showSabClass2) {
      this.clearClass2SabAntibodies();
    } else {
      this.updateSabAntibodiesClass2();
    }
    this.resetInvalidAntibodies();
  }

  /**
   * Refreshes HLA input components based on the selected HLA Laboratory's default Prefills.
   *
   * @listens hla_lab#changed
   */
  private changedHlaLaboratory(): void {
    // Refresh acceptable class I and class II antibodies
    this.updateSabAntibodiesClass1();
    this.updateSabAntibodiesClass2();
  }
  /**
   * Refreshes HLA input components based on user input.
   *
   * @listens antibodies_class1_unacceptable#input
   * @listens antibodies_class1_indeterminate#input
   * @listens antibodies_class2_unacceptable#input
   * @listens antibodies_class2_indeterminate#input
   */
  private onSabAntibodiesInput(sabClass: HlaSabClass, sabCategory: HlaSabCategory): void {
    switch (sabClass) {
      case HlaSabClass.Class1:
        this.updateSabAntibodiesClass1(sabCategory);
        break;
      case HlaSabClass.Class2:
        this.updateSabAntibodiesClass2(sabCategory);
        break;
    }
  }

  /**
   * Selects the HLA Antibodies Test clicked in the table, and sets the edit state based on its data.
   *
   * Fetches the HLA Antibodies Test ID from the selected table row, and then gets the full HLA Antibodies Test from
   * the sorted HLA Antibodies Test data. Stores form data extracted from the Test in the current edit state.
   *
   * @listens antibodies-tests#table-row-click
   */
  private selectHlaAntibodies(event: any): void {
    // Get HLA Antibodies Test ID from the table row referenced in the select event
    const selectedHlaAntibodiesId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    // Retrieve the HLA Antibodies Test object from the Vue-X Store
    const foundHlaAntibodies: LabHLAAntibody|undefined = this.hlaAntibodies.find((each: LabHLAAntibody) => {
      return each._id && each._id.$oid === selectedHlaAntibodiesId;
    });
    // Check if we successfully found the HLA Antibodies Test object
    if (foundHlaAntibodies) {
      // Store the HLA Antibodies Test object in an immutable attribute to be used for detecting input changes
      this.$store.commit('labs/selectHlaAntibodies', foundHlaAntibodies);
      // Clone the HLA Antibodies Test object into the editable page state
      this.$store.commit('pageState/set', {
        pageKey: 'hlaAntibodies',
        value: this.buildHLAAntibodiesForm(Object.assign({}, this.selectedHlaAntibodies))
      });
    }
  }

  // Class I antibodies tested by the selected Test Kit, or the selected laboratory's default Test Kit
  get testedClass1(): string[] {
    // Check default prefill based on selected laboratory
    const defaultTested = this.defaultHlaAcceptableClass1();
    // Check prefill for selected test kit
    const selectedKit = this.selectedTestKitClass1;
    const selectedPrefill = selectedKit?.prefill_antibodies || [];
    return selectedKit ? selectedPrefill : defaultTested;
  }

  get testedClass2(): string[] {
    // Check default prefill based on selected laboratory
    const defaultTested = this.defaultHlaAcceptableClass2();
    // Check prefill for selected test kit
    const selectedKit = this.selectedTestKitClass2;
    const selectedPrefill = selectedKit?.prefill_antibodies || [];
    return selectedKit ? selectedPrefill : defaultTested;
  }

  /**
   * Returns lab code for a given laboratory id
   *
   * @returns {string}  lab code
   */
  get userLabCode(): string|undefined {
    if (!this.hlaLaboratoryLookup) {
      return undefined;
    }
    const laboratory = this.hlaLaboratoryLookup.find((laboratory: Laboratory) => {
      return this.getAllUserLaboratoryIds.includes(laboratory._id.$oid);
    });
    return laboratory?.lab_code;
  }

  /**
   * Get hla laboratory lookup,
   * based on the user's laboratory id
   *
   * @returns {Laboratory[]} hla laboratory options
   */
  get laboratoryLookup(): Laboratory[]|[] {
    if(!this.hlaLaboratoryLookup) {
      return [];
    }

    return this.hlaLaboratoryLookup.filter((lab: Laboratory)=> {
      return this.getAllUserLaboratoryIds.includes(lab._id?.$oid);
    });
  }

  /**
   * Deselects any selected HLA Antibodies Tests, and sets the edit state to default values for a new Test.
   *
   * @listens antibodies-tests#table-create-row
   */
  private createHlaAntibodies(): void {
    this.$store.commit('labs/selectHlaAntibodies', undefined);
    const userLab = this.userLabCode;

    const defaultHLAAntibodies = this.newHLAAntibodies(userLab);

    // Generate standard blank HLA Antibodies Test Form
    const newHlaAntibodiesTest = this.buildHLAAntibodiesForm(Object.assign({}, defaultHLAAntibodies));

    // Pre-fill sample tested date on create hla antibodies
    newHlaAntibodiesTest.sample_tested_date = this.currentDateUi();

    // Pre-fill test kit class on create hla antibodies
    if (newHlaAntibodiesTest.testing_method_class1 == HlaAntibodyTestingMethod.Sab) {
      newHlaAntibodiesTest.testing_kit_class1 = this.mostRecentHlaTestingKitClass1Code;
    }
    if (newHlaAntibodiesTest.testing_method_class2 == HlaAntibodyTestingMethod.Sab) {
      newHlaAntibodiesTest.testing_kit_class2 = this.mostRecentHlaTestingKitClass2Code;
    }

    // Pre-fill acceptable antibodies based on selected HLA laboratory
    newHlaAntibodiesTest.antibodies = {
      class1_acceptable: this.standardizeAntibodies(this.testedClass1),
      class2_acceptable: this.standardizeAntibodies(this.testedClass2),
    };
    this.$store.commit('pageState/set', {
      pageKey: 'hlaAntibodies',
      value: newHlaAntibodiesTest
    });
    // Refresh acceptable class I and class II antibodies
    this.updateSabAntibodiesClass1();
    this.updateSabAntibodiesClass2();
  }

  // Public methods

  /**
   * Gets changes from the edit state as a patch for the selected HLA Antibodies Test.
   *
   * If there are no selected HLA Antibodies Tests, then the patch contains all parameters for the post request.
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    return this.editState ? this.extractHlaAntibodiesTestPatch(this.editState) : {};
  }

  /**
   * Saves the current edit state.
   *
   * Prepares HLA Antibodies payload, including endpoint IDs and parameter changes. Handles posting new entries as well
   * as patching existing entries. Registers a SaveResult indicating whether the save was successful. If successful,
   * then the SaveResult contains the HLA Antibodies Test record. Otherwise, the SaveResult contains a textual
   * description of the error(s) encountered as well as any field-specific validation errors that were raised.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHlaAntibodies as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'hlaAntibodies');
    // Generate payload based on current edit state
    const hlaAntibodiesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      id: this.selectedHlaAntibodies && this.selectedHlaAntibodies._id && this.selectedHlaAntibodies._id.$oid ? this.selectedHlaAntibodies._id!.$oid : undefined,
      recipientId: this.recipient.client_id,
      hlaAntibodiesTest: hlaAntibodiesPatch
    };
    // Dispatch save action and register the response
    this.resetInvalidAntibodies();
    this.resetValidationErrors();
    this.$store.dispatch('labs/saveHlaAntibodiesTest', payload).then((success: SaveResult) => {
      // Emit an event so that parent page can re-initialize other forms as needed
      this.$emit('saved');
      // If successful, reload all of the recipient's HLA Antibodies Tests and show success notification
      this.loadHlaAntibodiesTests();
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Handle antibody value errors locally
      this.handleAntibodyErrors(error);
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Reset field-level validations
  private resetValidationErrors(): void {
    this.$emit('clear');
  }

  // Reset per-antibody tag validations
  private resetInvalidAntibodies(): void {
    Vue.set(this.editState, 'invalidAntibodies', []);
  }

  /**
   * Extracts antibody value-specific errors from the validation errors
   */
  private handleAntibodyErrors(errorResult?: SaveResult): void {
    if (!this.editState.invalidAntibodies) {
      return;
    }
    const invalidAntibodies: { [key: string]: string[] } = {
      'class1_unacceptable_allele_group': [],
      'class1_unacceptable_allele_specific': [],
      'class1_unacceptable_alpha_beta': [],
      'class1_indeterminate_allele_group': [],
      'class1_indeterminate_allele_specific': [],
      'class1_indeterminate_alpha_beta': [],
      'class1_possible_allele_specific': [],
      'class2_unacceptable_allele_group': [],
      'class2_unacceptable_allele_specific': [],
      'class2_unacceptable_alpha_beta': [],
      'class2_indeterminate_allele_group': [],
      'class2_indeterminate_allele_specific': [],
      'class2_indeterminate_alpha_beta': [],
      'class2_possible_allele_specific': [],
      'epitopes_unacceptable': [],
      'epitopes_indeterminate': [],
    };
    // Iterate through validation errors
    const validationErrors: { [key: string]: string[] } = errorResult?.validationErrors || {};
    const errorObjects = Object.entries(validationErrors);
    errorObjects.forEach((entry: [string, string[]]) => {
      // Parse invalid antibody value from error key
      const key = entry[0];
      const antibodyAndField: { antibody?: string; fieldName?: string } = this.parseAntibodyFromErrorKey(key) || {};
      const antibody = antibodyAndField.antibody;
      const fieldName = antibodyAndField.fieldName || 'UNKNOWN';
      if (antibody) {
        const antibodies = invalidAntibodies[fieldName] || [];
        antibodies.push(antibody);
        invalidAntibodies[fieldName] = antibodies;
      }
    });
    // Update lists of invalid antibodies with a single mutation
    Vue.set(this.editState, 'invalidAntibodies', invalidAntibodies);
  }

  private parseAntibodyFromErrorKey(errorKey: string): { antibody?: string; fieldName?: string }|null {
    const antibodyInErrorKeyRegex = /^lab_hla_antibody\.antibodies\.(.*)\.antibodies\.(.*)\[(.*)\]$/;
    const regexResult = errorKey.match(antibodyInErrorKeyRegex);
    const antibody = regexResult ? regexResult[3] : 'UNKNOWN';
    const mhcClass = regexResult ? regexResult[1] : 'class1';
    const reactivity = regexResult ? regexResult[2] : 'unacceptable_allele_group';
    const fieldName = `${mhcClass}_${reactivity}`;
    return { antibody, fieldName };
  }

  /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveHlaAntibodies as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Populates form state with default values for a new HLA Antibodies Test.
   */
  public initializeForm(): void {
    this.createHlaAntibodies();
  }

  // Private methods

  /**
   * Fetch and store all HLA Antibodies Tests associated with the selected recipient.
   *
   * @emits loaded
   */
  private loadHlaAntibodiesTests(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch('labs/loadHlaAntibodies', recipientId).then(() => {
      this.initializeForm();
      this.$emit('loaded', 'hlaAntibodies');
    }).catch(() => {
      console.warn('Could not load recipient HLA Antibodies Tests');
    });
  }

  /**
   * Gets a patch representing changes to the selected HLA Antibodies Test.
   *
   * @return {LabHLAAntibody} patch or post request payload
   */
  private extractHlaAntibodiesTestPatch(hlaAntibodiesTest: HLAAntibodiesForm): LabHLAAntibody {
    // Fields common to all HLA Antibody Testing Methods
    const hla_class1_testing_method_code = this.selectedTestingMethodClass1;
    const hla_class2_testing_method_code = this.selectedTestingMethodClass2;
    const result = {
      test_code: hlaAntibodiesTest.sample_code,
      sample_date: this.sanitizeDateApi(hlaAntibodiesTest.sample_draw_date),
      hla_class1_testing_method_code,
      hla_class2_testing_method_code,
      lab_code: hlaAntibodiesTest.hla_lab || null,
      comments: hlaAntibodiesTest.comments,
      cpra: null, // NOTE: here we assume Combined cPRA can be set to null because API will set a value (TPGLI-6583)
    };
    // Fields for all Testing Methods except for Storage
    if (this.showNonStorage) {
      Object.assign(result, {
        test_date: this.sanitizeDateApi(hlaAntibodiesTest.sample_tested_date || undefined),
      });
    } else {
      // Do not save Sample Test Date if overall Testing Method = Storage
      Object.assign(result, {
        test_date: null,
      });
    }
    // PRA Class I
    if (this.showPraClass1) {
      // Store manually-entered PRA value
      Object.assign(result, {
        cpra_class1: hlaAntibodiesTest.cpra_class1,
      });
    } else if (!this.showSabClass1) {
      // Set to null if non-PRA and non-cPRA (i.e. Storage)
      Object.assign(result, {
        cpra_class1: null,
      });
    }
    // PRA Class II
    if (this.showPraClass2) {
      // Store manually-entered PRA value
      Object.assign(result, {
        cpra_class2: hlaAntibodiesTest.cpra_class2,
      });
    } else if (!this.showSabClass2) {
      // Set to null if non-PRA and non-cPRA (i.e. Storage)
      Object.assign(result, {
        cpra_class2: null,
      });
    }
    // SAB Test Kits
    const hla_class1_test_kit_code: string|null = this.showSabClass1 && this.selectedTestKitClass1 ? this.selectedTestKitClass1.code : null;
    const hla_class2_test_kit_code: string|null = this.showSabClass2 && this.selectedTestKitClass2 ? this.selectedTestKitClass2.code : null;
    Object.assign(result, { hla_class1_test_kit_code, hla_class2_test_kit_code });
    // SAB Antibody results (Unacceptable, Indeterminate, etc.)
    if (this.showSabClass1 || this.showSabClass2) {
      Object.assign(result, {
        antibodies: this.extractHlaAntibodiesSabDataPatch(hlaAntibodiesTest.antibodies),
      });
    } else {
      Object.assign(result, {
        antibodies: this.extractHlaAntibodiesSabDataPatch(),
      });
    }
    return result;
  }

  /**
   * Gets a patch representing changes to the SAB Antibodies data in the selected HLA Antibodies Test.
   *
   * @return {HlaAntibodyData} SAB antibody data
   */
  private extractHlaAntibodiesSabDataPatch(formData?: HlaAntibodyDataForm): HlaAntibodyData {
    const hlaAntibodiesSabData = formData || {};
    // Note: no need to send the Acceptable or Untested lists to back-end
    return {
      class1: {
        antibodies: {
          acceptable: [], // Note: acceptable arrays no longer used, so they should only ever have unused data from migrations
          unacceptable_allele_group: hlaAntibodiesSabData.class1_unacceptable || [],
          unacceptable_allele_specific: hlaAntibodiesSabData.class1_unacceptable_allele_specific || [],
          unacceptable_alpha_beta: hlaAntibodiesSabData.class1_unacceptable_alpha_beta || [],
          indeterminate_allele_group: hlaAntibodiesSabData.class1_indeterminate || [],
          indeterminate_allele_specific: hlaAntibodiesSabData.class1_indeterminate_allele_specific || [],
          indeterminate_alpha_beta: hlaAntibodiesSabData.class1_indeterminate_alpha_beta || [],
          possible_allele_specific: hlaAntibodiesSabData.class1_possible_allele_specific || [],
        },
        epitopes: {
          unacceptable: hlaAntibodiesSabData.epitopes_unacceptable || [],
          indeterminate: hlaAntibodiesSabData.epitopes_indeterminate || [],
        },
      },
      class2: {
        antibodies: {
          acceptable: [], // Note: acceptable arrays no longer used, so they should only ever have unused data from migrations
          unacceptable_allele_group: hlaAntibodiesSabData.class2_unacceptable || [],
          unacceptable_allele_specific: hlaAntibodiesSabData.class2_unacceptable_allele_specific || [],
          unacceptable_alpha_beta: hlaAntibodiesSabData.class2_unacceptable_alpha_beta || [],
          indeterminate_allele_group: hlaAntibodiesSabData.class2_indeterminate || [],
          indeterminate_allele_specific: hlaAntibodiesSabData.class2_indeterminate_allele_specific || [],
          indeterminate_alpha_beta: hlaAntibodiesSabData.class2_indeterminate_alpha_beta || [],
          possible_allele_specific: hlaAntibodiesSabData.class2_possible_allele_specific || [],
        },
      },
    };
  }

  /**
   * Refreshes antibody data entry elements in the Class 1 SAB form area.
   *
   * Sets Class 1 Acceptable antibodies to antibodies that are found in the selected Laboratory's Tested Prefill, but
   * not in Class 1 Unacceptable or Indeterminate. Sets Class 1 Allele-Specific to the allele-specific
   * antibodies extracted from the SAB data. Excludes antibodies entered in the HLA input component that emitted the
   * event triggering this function from the other Class 1 categories. E.g. If this function was triggered by an update
   * to Class 1 Unacceptable then all Class 1 Unacceptable antibodies are excluded from Class 1 Indeterminate
   *
   * @listens hla_lab#changed
   * @listens antibodies_class1_unacceptable#input
   * @listens antibodies_class1_indeterminate#input
   */
  private updateSabAntibodiesClass1(sabCategory?: HlaSabCategory): void {
    if (this.editState.antibodies) {
      // Fetch all related antibodies
      let unacceptable_allele_group = this.editState.antibodies.class1_unacceptable || [];
      let unacceptable_allele_specific = this.editState.antibodies.class1_unacceptable_allele_specific || [];
      let unacceptable_alpha_beta = this.editState.antibodies.class1_unacceptable_alpha_beta || [];
      let indeterminate_allele_group = this.editState.antibodies.class1_indeterminate || [];
      let indeterminate_allele_specific = this.editState.antibodies.class1_indeterminate_allele_specific || [];
      let indeterminate_alpha_beta = this.editState.antibodies.class1_indeterminate_alpha_beta || [];
      let epitopes_unacceptable = this.editState.antibodies.epitopes_unacceptable || [];
      let epitopes_indeterminate = this.editState.antibodies.epitopes_indeterminate || [];
      let possible_allele_specific = this.editState.antibodies.class1_possible_allele_specific || [];
      // Remove antibodies in updated category from other antibody categories in the same class
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          // Remove class I unacceptable antibodies from class I indeterminate
          indeterminate_allele_group = indeterminate_allele_group.filter((antibody: string) => {
            return !unacceptable_allele_group.includes(antibody);
          });
          indeterminate_allele_specific = indeterminate_allele_specific.filter((antibody: string) => {
            return !unacceptable_allele_specific.includes(antibody);
          });
          indeterminate_alpha_beta = indeterminate_alpha_beta.filter((antibody: string) => {
            return !unacceptable_alpha_beta.includes(antibody);
          });
          // Possible Allele Specific (legacy data only found in historical records)
          possible_allele_specific = possible_allele_specific.filter((antibody: string) => {
            return !unacceptable_allele_group.includes(antibody);
          });
          // Class I epitopes
          epitopes_indeterminate = epitopes_indeterminate.filter((antibody: string) => {
            return !epitopes_unacceptable.includes(antibody);
          });
          break;
        case HlaSabCategory.Indeterminate:
          // Remove class I indeterminate antibodies from class I unacceptable
          unacceptable_allele_group = unacceptable_allele_group.filter((antibody: string) => {
            return !indeterminate_allele_group.includes(antibody);
          });
          unacceptable_allele_specific = unacceptable_allele_specific.filter((antibody: string) => {
            return !indeterminate_allele_specific.includes(antibody);
          });
          unacceptable_alpha_beta = unacceptable_alpha_beta.filter((antibody: string) => {
            return !indeterminate_alpha_beta.includes(antibody);
          });
          // Possible Allele Specific (legacy data only found in historical records)
          possible_allele_specific = possible_allele_specific.filter((antibody: string) => {
            return !indeterminate_allele_group.includes(antibody);
          });
          // Class I epitopes
          epitopes_unacceptable = epitopes_unacceptable.filter((antibody: string) => {
            return !epitopes_indeterminate.includes(antibody);
          });
          break;
      }

      // Users input which antibodies are Unacceptable or Indeterminate
      let unacceptable = unacceptable_allele_group.concat(unacceptable_allele_specific).concat(unacceptable_alpha_beta).concat(epitopes_unacceptable);
      let indeterminate = indeterminate_allele_group.concat(indeterminate_allele_specific).concat(indeterminate_alpha_beta).concat(epitopes_indeterminate);
      const inputAntibodies = unacceptable.concat(indeterminate);

      // Fetch list of antibodies tested based on Laboratory, Sample Method, and Testing Kit
      const tested = this.standardizeAntibodies(this.testedClass1);

      // Build Acceptable list, and lists of antibodies to highlight specific to Allele-specific and Alpha-beta
      const alleleSpecific = this.buildAlleleSpecific(tested, inputAntibodies);
      const alphaBetaSpecific = this.buildAlphaBetaSpecific(tested, inputAntibodies);
      const acceptable = this.buildAcceptable(tested, inputAntibodies, alleleSpecific, alphaBetaSpecific, possible_allele_specific);

      // Fetch both Class I and Class II antibodies from form edit state
      const antibodies = this.editState.antibodies;

      // Set all Class I antibody fields to updated values
      Object.assign(antibodies, {
        class1_acceptable: acceptable,
        class1_allele_specific: alleleSpecific,
        class1_alpha_beta_specific: alphaBetaSpecific,
        class1_unacceptable: unacceptable_allele_group,
        class1_unacceptable_allele_specific: unacceptable_allele_specific,
        class1_unacceptable_alpha_beta: unacceptable_alpha_beta,
        class1_indeterminate: indeterminate_allele_group,
        class1_indeterminate_allele_specific: indeterminate_allele_specific,
        class1_indeterminate_alpha_beta: indeterminate_alpha_beta,
        class1_possible_allele_specific: possible_allele_specific,
        // Assumes all epitopes are class I
        epitopes_unacceptable: epitopes_unacceptable,
        epitopes_indeterminate: epitopes_indeterminate,
      });

      // Update form state with a single mutation
      Vue.set(this.editState, 'antibodies', antibodies);
    }
  }

  /**
   * Refreshes antibody data entry elements in the Class 2 SAB form area.
   *
   * Sets Class 2 Acceptable antibodies to antibodies that are found in the selected Laboratory's Tested Prefill, but
   * not in Class 2 Unacceptable or Indeterminate. Sets Class 2 Allele-Specific to the allele-specific
   * antibodies extracted from the SAB data. Excludes antibodies entered in the HLA input component that emitted the
   * event triggering this function from the other Class 2 categories. E.g. If this function was triggered by an update
   * to Class 2 Unacceptable then all Class 2 Unacceptable antibodies are excluded from Class 2 Indeterminate
   *
   * @listens hla_lab#changed
   * @listens antibodies_class2_unacceptable#input
   * @listens antibodies_class2_indeterminate#input
   */
  private updateSabAntibodiesClass2(sabCategory?: HlaSabCategory): void {
    if (this.editState.antibodies) {
      // Fetch all related antibodies
      let unacceptable_allele_group = this.editState.antibodies.class2_unacceptable || [];
      let unacceptable_allele_specific = this.editState.antibodies.class2_unacceptable_allele_specific || [];
      let unacceptable_alpha_beta = this.editState.antibodies.class2_unacceptable_alpha_beta || [];
      let indeterminate_allele_group = this.editState.antibodies.class2_indeterminate || [];
      let indeterminate_allele_specific = this.editState.antibodies.class2_indeterminate_allele_specific || [];
      let indeterminate_alpha_beta = this.editState.antibodies.class2_indeterminate_alpha_beta || [];
      let possible_allele_specific = this.editState.antibodies.class2_possible_allele_specific || [];
      // Remove antibodies in updated category from other antibody categories in the same class
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          // Remove class II unacceptable antibodies from class I indeterminate
          indeterminate_allele_group = indeterminate_allele_group.filter((antibody: string) => {
            return !unacceptable_allele_group.includes(antibody);
          });
          indeterminate_allele_specific = indeterminate_allele_specific.filter((antibody: string) => {
            return !unacceptable_allele_specific.includes(antibody);
          });
          indeterminate_alpha_beta = indeterminate_alpha_beta.filter((antibody: string) => {
            return !unacceptable_alpha_beta.includes(antibody);
          });
          // Possible Allele Specific (legacy data only found in historical records)
          possible_allele_specific = possible_allele_specific.filter((antibody: string) => {
            return !unacceptable_allele_group.includes(antibody);
          });
          break;
        case HlaSabCategory.Indeterminate:
          // Remove class II indeterminate antibodies from class I unacceptable
          unacceptable_allele_group = unacceptable_allele_group.filter((antibody: string) => {
            return !indeterminate_allele_group.includes(antibody);
          });
          unacceptable_allele_specific = unacceptable_allele_specific.filter((antibody: string) => {
            return !indeterminate_allele_specific.includes(antibody);
          });
          unacceptable_alpha_beta = unacceptable_alpha_beta.filter((antibody: string) => {
            return !indeterminate_alpha_beta.includes(antibody);
          });
          // Possible Allele Specific (legacy data only found in historical records)
          possible_allele_specific = possible_allele_specific.filter((antibody: string) => {
            return !indeterminate_allele_group.includes(antibody);
          });
          break;
      }

      // Users input which antibodies are Unacceptable or Indeterminate
      let unacceptable = unacceptable_allele_group.concat(unacceptable_allele_specific).concat(unacceptable_alpha_beta);
      let indeterminate = indeterminate_allele_group.concat(indeterminate_allele_specific).concat(indeterminate_alpha_beta);
      const inputAntibodies = unacceptable.concat(indeterminate);

      // Fetch list of antibodies tested based on Laboratory, Sample Method, and Testing Kit
      const tested = this.standardizeAntibodies(this.testedClass2);

      // Build Acceptable list, and lists of antibodies to highlight specific to Allele-specific and Alpha-beta
      const alleleSpecific = this.buildAlleleSpecific(tested, inputAntibodies);
      const alphaBetaSpecific = this.buildAlphaBetaSpecific(tested, inputAntibodies);
      const acceptable = this.buildAcceptable(tested, inputAntibodies, alleleSpecific, alphaBetaSpecific, possible_allele_specific);

      // Fetch both Class I and Class II antibodies from form edit state
      const antibodies = this.editState.antibodies;

      // Set all Class II antibody fields to updated values
      Object.assign(antibodies, {
        class2_acceptable: acceptable,
        class2_allele_specific: alleleSpecific,
        class2_alpha_beta_specific: alphaBetaSpecific,
        class2_unacceptable: unacceptable_allele_group,
        class2_unacceptable_allele_specific: unacceptable_allele_specific,
        class2_unacceptable_alpha_beta: unacceptable_alpha_beta,
        class2_indeterminate: indeterminate_allele_group,
        class2_indeterminate_allele_specific: indeterminate_allele_specific,
        class2_indeterminate_alpha_beta: indeterminate_alpha_beta,
        class2_possible_allele_specific: possible_allele_specific,
      });

      // Update form state with a single mutation
      Vue.set(this.editState, 'antibodies', antibodies);
    }
  }

  private onClass1AlleleSpecificInput(newValue: string[], sabCategory: HlaSabCategory): void {
    if (this.editState.antibodies) {
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          Vue.set(this.editState.antibodies, 'class1_unacceptable_allele_specific', newValue);
          break;
        case HlaSabCategory.Indeterminate:
          Vue.set(this.editState.antibodies, 'class1_indeterminate_allele_specific', newValue);
          break;
      }
    }
    this.updateSabAntibodiesClass1();
  }

  private onClass1AlphaBetaInput(newValue: string[], sabCategory: HlaSabCategory): void {
    if (this.editState.antibodies) {
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          Vue.set(this.editState.antibodies, 'class1_unacceptable_alpha_beta', newValue);
          break;
        case HlaSabCategory.Indeterminate:
          Vue.set(this.editState.antibodies, 'class1_indeterminate_alpha_beta', newValue);
          break;
      }
    }
    this.updateSabAntibodiesClass1();
  }

  private onClass2AlleleSpecificInput(newValue: string[], sabCategory: HlaSabCategory): void {
    if (this.editState.antibodies) {
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          Vue.set(this.editState.antibodies, 'class2_unacceptable_allele_specific', newValue);
          break;
        case HlaSabCategory.Indeterminate:
          Vue.set(this.editState.antibodies, 'class2_indeterminate_allele_specific', newValue);
          break;
      }
    }
    this.updateSabAntibodiesClass2();
  }

  private onClass2AlphaBetaInput(newValue: string[], sabCategory: HlaSabCategory): void {
    if (this.editState.antibodies) {
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          Vue.set(this.editState.antibodies, 'class2_unacceptable_alpha_beta', newValue);
          break;
        case HlaSabCategory.Indeterminate:
          Vue.set(this.editState.antibodies, 'class2_indeterminate_alpha_beta', newValue);
          break;
      }
    }
    this.updateSabAntibodiesClass2();
  }

  private onEpitopesInput(newValue: string[], sabCategory: HlaSabCategory): void {
    if (this.editState.antibodies) {
      switch (sabCategory) {
        case HlaSabCategory.Unacceptable:
          Vue.set(this.editState.antibodies, 'epitopes_unacceptable', newValue);
          break;
        case HlaSabCategory.Indeterminate:
          Vue.set(this.editState.antibodies, 'epitopes_indeterminate', newValue);
          break;
      }
    }
    this.updateSabAntibodiesClass1();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): IdLookup {
    // Constant mapping
    const result: { [key: string]: string } = {
      // HLA Antibodies Test metadata
      'lab_hla_antibody.lab_code' : 'antibodies-tests-hla_lab',
      'lab_hla_antibody.sample_date' : 'antibodies-tests-sample_draw_date',
      'lab_hla_antibody.test_code' : 'antibodies-tests-sample_code',
      'lab_hla_antibody.test_date' : 'antibodies-tests-sample_tested_date',
      'lab_hla_antibody.comments' : 'antibodies-tests-comments',
      'lab_hla_antibody.cpra_class1' : 'cpra_class1',
      'lab_hla_antibody.cpra_class2' : 'cpra_class2',
      'lab_hla_antibody.cpra' : 'combined_cpra',
      'lab_hla_antibody.hla_class1_testing_method_code' : 'antibodies-tests-testing_method_default',
      'lab_hla_antibody.hla_class2_testing_method_code' : 'antibodies-tests-testing_method_default',
      'lab_hla_antibody.hla_class1_test_kit_code' : 'antibodies-tests-testing_kit_class1',
      'lab_hla_antibody.hla_class2_test_kit_code' : 'antibodies-tests-testing_kit_class2',
      // Class 1 antibody fields
      'lab_hla_antibody.antibodies.class1.antibodies.unacceptable_allele_group'     : 'antibodies-tests-antibodies_class1_unacceptable',
      'lab_hla_antibody.antibodies.class1.antibodies.unacceptable_allele_specific'  : 'antibodies-tests-antibodies_class1_unacceptable_allele_specific',
      'lab_hla_antibody.antibodies.class1.antibodies.unacceptable_alpha_beta'       : 'antibodies-tests-antibodies_class1_unacceptable_alpha_beta',
      'lab_hla_antibody.antibodies.class1.antibodies.indeterminate_allele_group'    : 'antibodies-tests-antibodies_class1_indeterminate',
      'lab_hla_antibody.antibodies.class1.antibodies.indeterminate_allele_specific' : 'antibodies-tests-antibodies_class1_indeterminate_allele_specific',
      'lab_hla_antibody.antibodies.class1.antibodies.indeterminate_alpha_beta'      : 'antibodies-tests-antibodies_class1_indeterminate_alpha_beta',
      'lab_hla_antibody.antibodies.class1.antibodies.acceptable'                    : 'antibodies-tests-antibodies_class1_acceptable',
      'lab_hla_antibody.antibodies.class1.antibodies.possible_allele_specific'      : 'antibodies-tests-antibodies_class1_possible_allele_specific',
      'lab_hla_antibody.antibodies.class1.antibodies.untested'                      : 'antibodies-tests-antibodies_class1_acceptable_alpha_beta',
      // Class 2 antibody fields
      'lab_hla_antibody.antibodies.class2.antibodies.unacceptable_allele_group'     : 'antibodies-tests-antibodies_class2_unacceptable',
      'lab_hla_antibody.antibodies.class2.antibodies.unacceptable_allele_specific'  : 'antibodies-tests-antibodies_class2_unacceptable_allele_specific',
      'lab_hla_antibody.antibodies.class2.antibodies.unacceptable_alpha_beta'       : 'antibodies-tests-antibodies_class2_unacceptable_alpha_beta',
      'lab_hla_antibody.antibodies.class2.antibodies.indeterminate_allele_group'    : 'antibodies-tests-antibodies_class2_indeterminate',
      'lab_hla_antibody.antibodies.class2.antibodies.indeterminate_allele_specific' : 'antibodies-tests-antibodies_class2_indeterminate_allele_specific',
      'lab_hla_antibody.antibodies.class2.antibodies.indeterminate_alpha_beta'      : 'antibodies-tests-antibodies_class2_indeterminate_alpha_beta',
      'lab_hla_antibody.antibodies.class2.antibodies.acceptable'                    : 'antibodies-tests-antibodies_class2_acceptable',
      'lab_hla_antibody.antibodies.class2.antibodies.possible_allele_specific'      : 'antibodies-tests-antibodies_class2_possible_allele_specific',
      'lab_hla_antibody.antibodies.class2.antibodies.untested'                      : 'antibodies-tests-antibodies_class2_acceptable_alpha_beta',
      // Epitope fields
      'lab_hla_antibody.antibodies.class1.epitopes.unacceptable'                : 'antibodies-tests-antibodies_class1_unacceptable_epitopes',
      'lab_hla_antibody.antibodies.class2.epitopes.indeterminate'               : 'antibodies-tests-antibodies_class1_indeterminate_epitopes',
    };
    // Dynamic mapping
    // SAB antibody fields
    const antibodies = this.editState.antibodies || {};
    // Get current values from form edit state
    const class1_unacceptable_allele_group = antibodies.class1_unacceptable || [];
    const class1_unacceptable_allele_specific = antibodies.class1_unacceptable_allele_specific || [];
    const class1_unacceptable_alpha_beta = antibodies.class1_unacceptable_alpha_beta || [];
    const class1_indeterminate_allele_group = antibodies.class1_indeterminate || [];
    const class1_indeterminate_allele_specific = antibodies.class1_indeterminate_allele_specific || [];
    const class1_indeterminate_alpha_beta = antibodies.class1_indeterminate_alpha_beta || [];
    const class1_possible_allele_specific = antibodies.class1_possible_allele_specific || [];
    const class2_unacceptable_allele_group = antibodies.class2_unacceptable || [];
    const class2_unacceptable_allele_specific = antibodies.class2_unacceptable_allele_specific || [];
    const class2_unacceptable_alpha_beta = antibodies.class2_unacceptable_alpha_beta || [];
    const class2_indeterminate_allele_group = antibodies.class2_indeterminate || [];
    const class2_indeterminate_allele_specific = antibodies.class2_indeterminate_allele_specific || [];
    const class2_indeterminate_alpha_beta = antibodies.class2_indeterminate_alpha_beta || [];
    const class2_possible_allele_specific = antibodies.class2_possible_allele_specific || [];
    // Epitopes in Class 1
    const class1_unacceptable_epitopes = antibodies.epitopes_unacceptable || [];
    const class1_indeterminate_epitopes = antibodies.epitopes_indeterminate || [];
    // Class 1 unacceptable validations
    class1_unacceptable_allele_group.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.unacceptable_allele_group[${antibody}]`] = 'antibodies-tests-antibodies_class1_unacceptable';
    });
    class1_unacceptable_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.unacceptable_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class1_unacceptable_allele_specific';
    });
    class1_unacceptable_alpha_beta.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.unacceptable_alpha_beta[${antibody}]`] = 'antibodies-tests-antibodies_class1_unacceptable_alpha_beta';
    });
    // Class 1 indeterminate validations
    class1_indeterminate_allele_group.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.indeterminate_allele_group[${antibody}]`] = 'antibodies-tests-antibodies_class1_indeterminate';
    });
    class1_indeterminate_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.indeterminate_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class1_indeterminate_allele_specific';
    });
    class1_indeterminate_alpha_beta.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.indeterminate_alpha_beta[${antibody}]`] = 'antibodies-tests-antibodies_class1_indeterminate_alpha_beta';
    });
    // Class 1 possible allele specific validations
    class1_possible_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.antibodies.possible_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class1_possible_allele_specific';
    });
    // Class 2 unacceptable validations
    class2_unacceptable_allele_group.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.unacceptable_allele_group[${antibody}]`] = 'antibodies-tests-antibodies_class2_unacceptable';
    });
    class2_unacceptable_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.unacceptable_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class2_unacceptable_allele_specific';
    });
    class2_unacceptable_alpha_beta.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.unacceptable_alpha_beta[${antibody}]`] = 'antibodies-tests-antibodies_class2_unacceptable_alpha_beta';
    });
    // Class 2 indeterminate validations
    class2_indeterminate_allele_group.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.indeterminate_allele_group[${antibody}]`] = 'antibodies-tests-antibodies_class2_indeterminate';
    });
    class2_indeterminate_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.indeterminate_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class2_indeterminate_allele_specific';
    });
    class2_indeterminate_alpha_beta.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.indeterminate_alpha_beta[${antibody}]`] = 'antibodies-tests-antibodies_class2_indeterminate_alpha_beta';
    });
    // Class 2 possible allele specific validations
    class2_possible_allele_specific.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class2.antibodies.possible_allele_specific[${antibody}]`] = 'antibodies-tests-antibodies_class2_possible_allele_specific';
    });
    // Epitopes in Class 1
    class1_unacceptable_epitopes.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.epitopes.unacceptable[${antibody}]`] = 'antibodies-tests-antibodies_class1_unacceptable_epitopes';
    });
    class1_indeterminate_epitopes.forEach((antibody: string) => {
      result[`lab_hla_antibody.antibodies.class1.epitopes.indeterminate[${antibody}]`] = 'antibodies-tests-antibodies_class1_indeterminate_epitopes';
    });
    return result;
  }
}
