

import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { SortedTable, SortLink } from 'vue-sorted-table';
import { mixins } from "vue-class-component";
import { AllocationUtilsMixin } from "@/mixins/allocation-utils-mixin";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import ModalSection from '@/components/shared/ModalSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import OpoTransplantDetailsModal from '@/components/allocations/OpoTransplantDetailsModal.vue';
import { Allocation, AllocationRecipient, AllocationOfferTypeValues, AllocationOfferResponseCodeValues, AllocationOfferRecipient, RegistrationType, OfferOutcomeContext, SMC_MEDICAL_STATUS, MELD_MEDICAL_STATUS } from '@/store/allocations/types';
import SubSection from '@/components/shared/SubSection.vue';
import OfferModal from '@/components/allocations/offers/OfferModal.vue';
import OfferHistoryModal from '@/components/allocations/offers/OfferHistoryModal.vue';
import OfferIcon from '@/components/allocations/offers/OfferIcon.vue';
import OfferTimers from '@/components/allocations/offers/OfferTimers.vue';
import DiscontinueModal from '@/components/allocations/_DiscontinueModal.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import CompareModal from '@/components/deceasedDonors/CompareModal.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { OrganCodeValue } from '@/store/lookups/types';
import { GenericCodeValue } from '@/store/types';
import { organCodeLookup } from '@/types';
import { recipients } from '@/store/recipients';
import {EP} from "@/api-endpoints";
import {VueGoodTable} from "vue-good-table";
import TextInput from "@/components/shared/TextInput.vue";
import { listedForCodes } from '@/allocation-utils';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";


interface AllocationRecipientCell {
  _id: string;
  selected?: boolean;
  client_id?: number;
  offer?: RecipientOfferCell;
  response?: string;
  rank?: number;
  effective_rank?: number;
  ctr_rank?: number|null;
  last_name?: string;
  program?: string|null;
  province_code?: string|null;
  medical_status?: string;
  secondary_medical_status?: string;
  blood_type?: string|null;
  hospital_abbreviation?: string|null;
  hsp?: string;
  allocation_points?: number|null;
  mpe_score?: number|null;
  listed_for?: string[];
  sex?: string|null;
  age?: number|null;
  height?: number|null;
  weight?: number|null;
  cpra?: number|null;
  status?: string;
  disableCompareModal?: boolean;
  disableOfferActions?: boolean;
  registration_type?: string|null;
  organ_code: number;
  isOffered: boolean;
  hsh?: boolean;
  on_wait_time?: string;
  on_wait_time_date?: string;
  initial_list_date: string;
  ctr_wait_time?: string;
  ctr_wait_time_date?: string;
  adjusted_organ_cpra?: number|null;
  crossed_antigens?: string|null;
  added_from_superceded?: boolean;
  missing_from_superceded?: boolean;
}

interface RecipientOfferCell {
  offer_type_code?: string|null;
  response_code?: string|null;
  organ_specification_code?: string|null;
  offered_by?: string|null;
  response_reason_category_code?: string|null;
  response_by?: string|null;
  responsible_physician_id?: string|null;
}

const ALLOCATION_STATES_TRANSPLANT_DETAILS_ENABLED = [
  'offer-confirmed',
  'concluded',
];

@Component({
  components: {
    OfferModal,
    OfferHistoryModal,
    OfferIcon,
    OfferTimers,
    DiscontinueModal,
    SubSection,
    CheckboxInput,
    SortedTable,
    SortLink,
    ModalSection,
    CompareModal,
    SelectInput,
    VueGoodTable,
    TextInput,
    OpoTransplantDetailsModal,
  }
})

export default class AllocationRecommendationListing extends mixins(AllocationUtilsMixin, DateUtilsMixin, TranslationUtilsMixin) {
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.allocationRecommendations) editState!: any;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isMakingOffer) private isMakingOffer!: boolean;
  @State(state => state.allocations.isDiscontinuingOneOffer) private isDiscontinuingOneOffer!: boolean;

  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('recipients', { namespace: 'allocations' }) private recipients!: AllocationRecipient[];
  @Getter('primaryOffers', { namespace: 'allocations' }) private primaryOffers!: AllocationRecipient[];
  @Getter('backupOffers', { namespace: 'allocations' }) private backupOffers!: AllocationRecipient[];
  @Getter('noOffers', { namespace: 'allocations' }) private noOffers!: AllocationRecipient[];
  @Getter('isAllocationOfferable', { namespace: 'allocations' }) private isAllocationOfferable!: boolean;
  @Getter('offerResponseCode', { namespace: 'lookups' }) private offerResponseCode!: (code: string) => string;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('manuallyAddedOutOfProvinceEntries', { namespace: 'allocations' }) private manuallyAddedOutOfProvinceEntries!: AllocationRecipient[];
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('getRecipientsByEffectiveRank', { namespace: 'allocations' }) getRecipientsByEffectiveRank!: (recipientRanks?: number[]) => AllocationOfferRecipient[];
  @Getter('determineHspValue', { namespace: 'allocations' }) determineHspValue!: (recipient?: AllocationRecipient) => string;
  @Getter('determineHshValue', { namespace: 'allocations' }) determineHshValue!: (recipient?: AllocationRecipient) => string;
  @Getter('allPrimaryBackupOffers', { namespace: 'allocations' }) private allPrimaryBackupOffers!: AllocationRecipient[];
  @Getter('showIposForAllocation', { namespace: 'allocations' }) private showIposForAllocation!: boolean;

  private loadingExcludedRecipients = false;
  private excludedRecipients: any[] = [];
  public searchParams: { [key: string]: { value: string; exact: boolean; }} = {};

  /**
   * Checks to see if listed for contains kidney and pancreas
   *
   * @param listed_for_codes  all the listed_for organ codes
   * @returns {boolean} true / false
   */
  listedForIncludesKidneyAndPancreasCombination(listed_for_codes: string[]|undefined): boolean {
    // if no listed_for codes, return false
    if (!listed_for_codes || Array.isArray(listed_for_codes) && listed_for_codes.length <= 1) return false;
    // catch strange case where listed_for_codes returns string
    if (typeof listed_for_codes == 'string') return false;
    // if kidney & pancreas whole return true
    const organs: string[] = [];
    listed_for_codes.map((item: string) => {
      // separate organs, if we find one clustered split it so we have one array containing separate organs
      if (item.includes('/')) {
        const separated_organs = item.split('/');
        separated_organs.map((single_organ: string) => {
          organs.push(single_organ);
        });
      } else {
        organs.push(item);
      }
    });
    // deduplicate the array
    const unique_organs = [...new Set(organs)];

    // use that to see if we have a kidney & pancreas
    // ...regardless of whether it's made up of [kidney/lung, pancreas] or [kidney/pancreas, lung] or [kidney, pancreas, lung/liver]
    return unique_organs.includes(OrganCodeValue.Kidney.toString()) && unique_organs.includes(OrganCodeValue.PancreasWhole.toString());
  }

  /**
   * Checks to see if organ listed should be highlighted
   *
   * @param organ_code         the 'organ' row's organ code when it's a single organ
   * @param cluster_organ_code the 'organ' row's organ code when it's a cluster
   * @param listed_for_code    this particular listed_for organ code
   * @param listed_for_codes   all the listed_for organ codes
   * @returns {boolean} true if should be highlighted
   */
  highlightOrgan(organ_code: string, cluster_organ_code: string, listed_for_code: string, listed_for_codes: string[]): boolean {
    const real_organ_code = cluster_organ_code ? cluster_organ_code : organ_code;
    if (!listed_for_code) { return false; }
    if (this.allocation.organ_code != OrganCodeValue.Kidney) { return false; }
    if (real_organ_code == '3/6') { return false; }
    const listedForKidneyPancreas = this.listedForIncludesKidneyAndPancreasCombination(listed_for_codes);
    return real_organ_code == listed_for_code && listedForKidneyPancreas;
  }

  get showControls(): boolean {
    if (this.isTransplantCoordinator) { return false; }
    return this.checkAllowed("/donors/:donor_id/organs/:organ_id/allocations/:allocation_id/offers", "POST");
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens allocationRecommendationListing#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'allocationRecommendationListing');
  }

  /**
   * Return if we're allowed to discontinue offers
   *
   * Allocation state is offering, offer-accepted or offer-confirmed and there are selected rows
   *
   * @returns {boolean} true if we're allowed to discontinue offers
   */
  get discontinueAble(): boolean {
    if (!this.allocation.state) return false;
    return (this.allocation.state === 'offering' || this.allocation.state === 'offer-accepted' || this.allocation.state === 'offer-confirmed') && this.hasSelectedIds;
  }

  // Generate column list by Organ Code
  get columnsByOrgan(): any[] {
    let columns = ['offer', 'organ_spec_offered', 'response', 'effective_rank', 'client_id',
      'last_name', 'hospital_abbreviation', 'province_code', 'medical_status', 'hsh', 'on_wait_time',
      'on_wait_time_date', 'initial_list_date', 'ctr_wait_time', 'ctr_wait_time_date', 'sec_status',
      'hsp', 'allocation_points', 'mpe_score', 'abo', 'organ', 'listed_for', 'registration_type',
      'sex', 'age', 'height', 'weight', 'cpra', 'adjusted_organ_cpra', 'unadjusted_cpra',
      'crossed_antigens', 'rec_status'];

    // TODO: Clean up this case or replace it with something better
    switch(this.allocationOrganCode) {
      case OrganCodeValue.SmallBowel:
      case OrganCodeValue.VCA:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.Liver:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "cpra");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.PancreasWhole:
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.PancreasIslets:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.Kidney:
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        if (this.ctrIposKidney) {
          // hide for ipos kidney
          columns = columns.filter((item: string) => item !== "cpra");
        } else {
          // hide for non ipos kidney
          columns = columns.filter((item: string) => item !== "unadjusted_cpra");
          columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
          columns = columns.filter((item: string) => item !== "crossed_antigens");
        }
        break;
      case OrganCodeValue.Lung:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.Heart:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        if (this.showIposForAllocation) {
          // hide for ipos heart
          columns = columns.filter((item: string) => item !== "sec_status");
        } else {
          // hide for non-ipos heart
          columns = columns.filter((item: string) => item !== "hsh");
          columns = columns.filter((item: string) => item !== "on_wait_time");
          columns = columns.filter((item: string) => item !== "on_wait_time_date");
          columns = columns.filter((item: string) => item !== "initial_list_date");
          columns = columns.filter((item: string) => item !== "ctr_wait_time");
          columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        }
        break;
      default:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "sec_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "on_wait_time");
        columns = columns.filter((item: string) => item !== "on_wait_time_date");
        columns = columns.filter((item: string) => item !== "initial_list_date");
        columns = columns.filter((item: string) => item !== "ctr_wait_time");
        columns = columns.filter((item: string) => item !== "ctr_wait_time_date");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
    }
    return columns;
  }

  /** Filter offers by search params
   *
   * @param event input event object
   * @param field field name
   * @param exact does the value need to match exactly (no lowerCase match)
   */
  public updateFilters(event: any, field: string, exact: boolean) {
    this.searchParams[field] = { value: event, exact: exact };

    let records = this.editState.recordsAll;
    Object.keys(this.searchParams).map((key:string) => {
      records = this.searchAllocationsBy(records, key, this.searchParams[key].value, this.searchParams[key].exact);
    });
  }

  /**
   * Search Allocation Recipients and filter the listing
   *
   * This will filter the Allocation Recipients
   * based on the the search params and respective values.
   *
   * @param records records to filter by
   * @param column column we intend to search
   * @param value value we intend to search by
   * @param exact does the value need to match exactly (no lowerCase match)
   */
  private searchAllocationsBy(records:any, column: string|undefined, value: string, exact: boolean) {
    const results = records.filter((item: any) => {
      let props = (value && column) ? [item[column]] : Object.values(item);

      return props.some((prop: any) => {
        // If no value we're defaulting back to showing all values
        if (!value) return true;
        // Flag to determine if we should return this record
        let returnValue = false;
        // Lower case all items if they're an array (this would only be the listed_for column)
        const filteredProp: string[] = Array.isArray(prop) ? (prop.map((item: string) => item.toLowerCase())) : [prop];

        // Are we looking for an exact match?
        if (exact) {
          returnValue = filteredProp[0] === value;
        } else {
          // If we're looking through the listed_for column we need to look at
          // the array, otherwise join all the items and search that.
          if (column === 'listed_for') {
            returnValue = filteredProp.includes(value.toLowerCase());
          } else {
            returnValue = filteredProp.join('').toLowerCase().includes(value.toLowerCase());
          }
        }
        // Return true if we found a matching value
        return returnValue || false;
      });
    });

    Vue.set(this.editState, 'records', results);
    Vue.set(this.editState, 'selectAllMatchingRows', false); // uncheck 'select all'

    return results;
  }

  get getListedForOptions(): any[] {
    if (this.recipients.length <= 0) return [];
    const options: any[] = [];
    this.recipients.filter((recipient: AllocationRecipient) => {
      // Fallback to showing single organ based on organ_code for any rows missing listed_for
      const listed_for = recipient.listed_for && recipient.listed_for.length > 0 ? recipient.listed_for : [this.clusterOrganCodeDisplayValue(recipient.organ_code, recipient.cluster_organ_code)];
      (listed_for || []).forEach((item: string) => options.push(item));
    });
    const filteredOptions = [...new Set(options)];
    const selectOptions: GenericCodeValue[] = [];
    filteredOptions.map((item: string) => {
      selectOptions.push({code: item, value: item});
    });
    return selectOptions;
  }

  get getRegistrationTypeOptions(): any[] {
    if (this.editState.recordsAll.length <= 0) return [];
    const options: any[] = [];
    // get all current types
    this.editState.recordsAll.filter((recipient: AllocationRecipient) => {
      options.push(recipient.registration_type);
    });
    // de-duplicate list
    const filteredOptions = [...new Set(options)];
    // return options
    const selectOptions: GenericCodeValue[] = [];
    filteredOptions.map((item: string) => {
      selectOptions.push({code: item, value: item});
    });
    return selectOptions;
  }

  public checkRow(event: any, row: any): void {
    if (row && row.effective_rank) {
      if (event.target.checked) {
        this.editState.records.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = true;
          }
        });
      } else {
        this.editState.records.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = false;
          }
        });
      }
    }
  }

  /**
   * Show the Recipient/Donor comparison pop-up for the specified row
   *
   * @param row recipient entry from allocation list
   */
  private openCompareModal(row: AllocationRecipientCell): void {
    const recipientId = row._id;
    const journeyOrganCode = row.organ_code;
    const rank = row.rank;
    (this.$refs.compareModal as CompareModal).initializeAllocationCompare(recipientId, journeyOrganCode, rank);
  }

  public mounted(): void {
    // ATU-9250
    // We decided to do an anti-pattern of lookupsToLoad here
    // to make sure lookups will be loaded before initializing the form
    // TECH DEBT: TO DO
    // refactor Subsection lookup process to emit `loaded` only
    // once all of the lookupsToLoad is already loaded and stored
    Promise.all([
      this.$store.dispatch('lookups/queueLookup', { lookup: 'offer_responses' }),
      this.$store.dispatch('lookups/queueLookup', { lookup: 'out_of_sequence_offer_reasons' }),
      this.$store.dispatch('lookups/queueLookup', { lookup: 'donor_exceptional_distribution' }),
      this.$store.dispatch('lookups/queueLookup', { lookup: 'oop_recipient_notification_email' }),
    ]).finally(() => {
      this.initializeForm();
    });
  }

  /**
   * Watch for changes to the recipients
   *
   * @listens recipients#changed
   */
  @Watch('recipients', { immediate: true, deep: true })
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'allocationRecommendations',
      value: {
        recordsAll: this.buildAllocationRecommendations(this.recipients), // used for searching
        records: this.buildAllocationRecommendations(this.recipients), // used for selection, altered
        selectAllMatchingRows: false,
        programFilterValue: null,
        listedForFilterValue: null,
        programOptions: this.getHospitalPrograms(this.recipients)
      }
    });
  }

  // Sanitize 'Listed For' information
  private listedFor(record: AllocationRecipient): string[] {
    let result: string[] = record.listed_for && record.listed_for.length > 0 ? record.listed_for : [this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code)];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }

    return result;
  }

  public buildAllocationRecommendations(recipients: AllocationRecipient[]): AllocationRecipientCell[] {
    if (!recipients) {
      return [];
    }
    const results: any[] = [];
    recipients.forEach((record: AllocationRecipient) => {
      const hspValue = this.determineHspValue(record);
      const waitlistedFor = this.listedFor(record);
      const registrationType = this.parseRegistrationType(record.registration_type, waitlistedFor);
      const row: any = {
        selected: false,
        _id: record._id,
        offer: record.offer?.offer_type_code || undefined,
        organ_spec_offered: record.offer && record.offer?.organ_specification_code ? this.getOrganSpecificationName(this.allocation.organ_code, record.offer?.organ_specification_code) : null,
        response: record.offer?.response_code || undefined,
        // response_value is used for searching inside the response column
        response_value: `${this.offerResponseCodeValue({ response: record.offer?.response_code, offer: record.offer?.offer_type_code })} ${record.offer?.response_code}`,
        // response_for_sorting is only used for sorting code. 'zzz' is in place of blank values for sorting a,b,c,blank,blank,blank
        response_for_sorting: record.offer?.response_code || 'zzz',
        rank: record.rank || undefined,
        effective_rank: record.effective_rank || undefined,
        display_rank: record.rank && !record.added_manually ? record.effective_rank || undefined : null,
        ctr_rank: record.ctr_rank,
        client_id: record.client_id || undefined,
        isOffered: (record.offer) ? true : false,
        last_name: record.last_name || '-',
        program: record.program || '-',
        hospital_abbreviation: record.hospital_abbreviation || record.program || '-',
        province_code: record.province_code || '-',
        medical_status: (record.medical_status || '-').replaceAll(SMC_MEDICAL_STATUS, MELD_MEDICAL_STATUS),
        sec_status: record.secondary_medical_status || '-',
        abo: record.blood_type || '-',
        hsp: hspValue,
        allocation_points: record.allocation_points == null ? '-' : record.allocation_points,
        mpe_score: record.mpe_score || '-',
        organ: this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code),
        cluster_organ_code: record.cluster_organ_code,
        organ_code: record.organ_code,
        listed_for: waitlistedFor,
        listed_for_codes: listedForCodes(record),
        sex: record.sex || '-',
        age: record.age === 0 ? 0 : (record.age || '-'),
        height:  record.out_of_province ? '-' :record.height || '-',
        weight:  record.out_of_province ? '-' :record.weight || '-',
        cpra: record.cpra === 0 ? 0 : (record.cpra || '-'),
        adjusted_organ_cpra: record.adjusted_organ_cpra === 0 ? 0 : (record.adjusted_organ_cpra || '-'),
        crossed_antigens: record.crossed_antigens ? record.crossed_antigens : '-',
        rec_status: record.status || '-',
        tip_donor_client_id: record.transplant_in_progress ? record.transplant_in_progress.donor_client_id : null,
        tip_deceased_donor_id: record.transplant_in_progress ? record.transplant_in_progress.deceased_donor_id : null,
        /**
         * Oct 18, 2021 - Overridden to never disable the compare modal as we will need to to enter transplant details
         * for manually added out of province recipients.
         */
        disableCompareModal: false,
        // Prevent interaction with rows where Allocation Service has 'entry_offer_actions_enabled: false'
        disableOfferActions: record.entry_offer_actions_enabled === false,
        registration_type: registrationType || '-',
        // IPOS Heart Data
        hsh: this.determineHshValue(record),
        on_wait_time: record.wait_days === 0 ? 0 : (record.wait_days || '-'),
        on_wait_time_date: this.parseDisplayDateUiFromDateTime(record.wait_days_date) || '-',
        initial_list_date: this.parseDisplayDateUiFromDateTime(record.listing_date) || '-',
        ctr_wait_time: record.ctr_wait_days === 0 ? 0 : (record.ctr_wait_days || '-'),
        ctr_wait_time_date: this.parseDisplayDateUiFromDateTime(record.ctr_wait_days_date) || '-',
        missing_from_superceded: record.missing_from_superceded,
        added_from_superceded: record.added_from_superceded,
      };

      results.push(row);
    });
    return results;
  }

  public openPrimaryOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.Primary);
  }

  public openBackupOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.Backup);
  }

  public openNoOfferHistory(): void {
    const offerHistoryModal = this.$refs.offerHistoryModal as OfferHistoryModal;
    offerHistoryModal.initializeModal(AllocationOfferTypeValues.NoOffer);
  }

  get getSelectedIds(): number[] {
    const rows = this.editState.records || [];
    const selectedIds: Set<number> = new Set();

    // These rows are now using effective_rank an an identifier
    rows.forEach((item: AllocationRecipientCell) => {
      if (item.selected && typeof item.effective_rank === 'number') {
        selectedIds.add(item.effective_rank);
      }
    });
    return Array.from(selectedIds);
  }

  get hasSelectedIds(): boolean {
    return this.getSelectedIds.length > 0;
  }

  get allocationOrganCode(): number|null {
    return this.allocation.organ_code || null;
  }

  public makeOffer(): void {
    if (this.hasSelectedIds) {
      if (this.allocation.organ_code === OrganCodeValue.Kidney && this.checkForMixOfHSPTypes(this.getSelectedIds)) {
        alert(this.translateIPOSContext('cannot_mix_hsp_with_non_hsp'));
      } else {
        const offerModal = this.$refs.offerModal as OfferModal;
        offerModal.initializeAllocationOffer(this.getSelectedIds);
      }
    }
  }

  /**
   * Check for mixture of hsp and non-hsp recipients
   *
   * @param recipientRanks selected ids
   * @returns {boolean} if mixture of hsp and non-hsp recipients detected
   */
  public checkForMixOfHSPTypes(recipientRanks: number[]): boolean {
    const recipients = this.getRecipientsByEffectiveRank(recipientRanks);

    // find hsp and non-hsp recipients
    const hspRecipients = recipients.filter((record: AllocationOfferRecipient) => { return record.hsp == 'HSP'; });
    const nonHspRecipients = recipients.filter((record: AllocationOfferRecipient) => { return record.hsp != 'HSP'; });

    return hspRecipients.length > 0 && nonHspRecipients.length > 0;
  }

  public discontinueOffer(): void {
    if (this.hasSelectedIds) {
      const discontinueModal = this.$refs.discontinueModal as DiscontinueModal;
      discontinueModal.initializeDiscontinueModal(this.getSelectedIds);
    }
  }

  private closeModal(): void {
    this.editState.records.map((item: any) => {
      item.selected = false;
    });
  }

  public selectAllMatchingRows(checked: boolean): void {
    if (checked) {
      this.editState.records.map((item: AllocationRecipientCell) => {
        // make offer: can do multiple offers regardless of sequence for backup and no-offer
        // also, don't select recipient entries that have offer actions disabled for any reason
        item.selected = (!item.offer && !item.response && !item.disableOfferActions) ? true : false;
      });
    } else {
      this.editState.records.map((item: AllocationRecipientCell) => {
        item.selected = false;
      });
    }
  }

  public rowStyleClassFn(row: any): string|undefined {
    if (row.missing_from_superceded) {
      return "row-missing-from-superceded";
    }
  }

  getAllocationRowStyle(row: any): string {
    let style = [];
    // if recipient status is transplant in progress then push style
    if (row.tip_donor_client_id) {
      style.push("highlight-tip");
    }

    // offer circle style
    switch(row.offer) {
      case AllocationOfferTypeValues.Primary:
        // primary
        style.push("offer-row-primary");
        break;
      case AllocationOfferTypeValues.Backup:
        // backup
        style.push("offer-row-backup");
        break;
      case AllocationOfferTypeValues.NoOffer:
        // no offer (will also have to provide reason_category & reason_code)
        style.push("offer-row-no-offer");
        break;
      default:
        // null = no offer
        style.push("offer-row-unoffered");
        break;
    }
    // offer response style
    switch(row.response) {
      case AllocationOfferResponseCodeValues.Accept:
        style.push("row-response-accepted");
        break;
      case AllocationOfferResponseCodeValues.AcceptWithCondition:
        style.push("row-response-accepted-condition");
        break;
      case AllocationOfferResponseCodeValues.Withdraw:
        style.push("row-response-declined");
        break;
      case AllocationOfferResponseCodeValues.Cancel:
        style.push("row-response-declined");
        break;
      case AllocationOfferResponseCodeValues.Decline:
        style.push("row-response-declined");
        break;
      default:
        // do nothing
        break;
    }
    if (row.hsp === "HSP") style.push("hsp-row");
    if (row.added_from_superceded) style.push("row-added-from-superceded");

    // add style to indicate that offer actions are disabled for certain rows
    if (row.disableOfferActions) style.push("row-response-declined");

    return style.join(" ");
  }

  /**
   * Return response column text
   *
   * Check for a response code and return that, otherwise check if
   * this is a primary or backup offer without a response.
   *
   * @param row table row
   * @returns {string} text for the response code
   */
  private offerResponseCodeValue(row: any): string {
    // get the response value from the code
    const response: string = this.offerResponseCode(row.response);
    if (!!response) {
      return response;
    }
    if (row.offer === AllocationOfferTypeValues.Backup || row.offer === AllocationOfferTypeValues.Primary) {
      return "No Response";
    }
    return "";
  }

  /**
   * Return table cell css class
   *
   * Check for an offer, if there is one check the response to
   * determine which css class should be applied.
   *
   * @param row table row
   * @returns {string} css class
   */
  private getAllocationCellStyle(row: any): string {
    const style = [];
    // do we have an offer?
    if (!!row.offer && row.offer != AllocationOfferTypeValues.NoOffer) {
      // accepted or accepted with condition
      if (row.response === AllocationOfferResponseCodeValues.Accept || row.response === AllocationOfferResponseCodeValues.AcceptWithCondition) {
        style.push("response-accepted");
      }
      // declined
      if (row.response === AllocationOfferResponseCodeValues.Decline) {
        style.push("response-declined");
      }
      // no response
      if (!row.response) {
        style.push("response-none");
      }
    }
    return style.join(" ");
  }

  // Offer outcome notification events bubble up to the view
  private displayOutcomeNotification(context: OfferOutcomeContext) {
    this.$emit('display-outcome-notification', context);
  }

  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  /**
   * Check if the current user is allowed to view the excluded recipients
   *
   * @return boolean
   */
  get canViewExcludedRecipients(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.excluded_recipients);
  }

  /**
   * Method to trigger the API call to get the excluded recipients and show the modal
   */
  public showExcludedRecipients() {
    if(!this.allocation || !this.allocation._id) {
      return;
    }
    this.loadingExcludedRecipients = true;
    this.toggleModal('excludedRecipientsListModal');

    this.$store.dispatch('allocations/getExcludedRecipients', {
      donorId: this.allocation.client_id, organCode: this.allocation.organ_code, allocationId: this.allocation._id
    }).then((response) => {
      this.excludedRecipients = response.data.excluded_recipients.excluded_recipients;
      this.loadingExcludedRecipients = false;
    }).catch((_error) => {
      alert(this.$t('excluded_recipients_modal.loading_error'));
    });
  }

  public get excludedRecipientsTableConfig(): TableConfig {
    return {
      data: this.excludedRecipients,
      columns: [
        { label: this.$t('excluded_recipients_modal.table_columns.client_id'), field: 'client_id'},
        { label: this.$t('excluded_recipients_modal.table_columns.ctr_id'), field: 'national_recipient_id'},
        { label: this.$t('excluded_recipients_modal.table_columns.first_name'), field: 'first_name'},
        { label: this.$t('excluded_recipients_modal.table_columns.last_name'), field: 'last_name'},
        { label: this.$t('excluded_recipients_modal.table_columns.blood_type'), field: 'blood_type'},
        { label: this.$t('excluded_recipients_modal.table_columns.excluded_reason_code'), field: 'excluded_reason_code'},
        { label: this.$t('excluded_recipients_modal.table_columns.excluded_reason'), field: 'excluded_reason'},
      ],
      sortOptions: {
        enabled: false,
      },
    };
  }

  // Show the inline OPO Transplant Details button?
  get isTransplantDetailsPopupApplicable(): boolean {
    // Do we have any manually-added Out-of-Province Programs in the list?
    return this.manuallyAddedOutOfProvinceEntries.length > 0;
  }

  // Do we have an applicable OPO Program offer?
  get acceptedPrimaryOfferToOPOProgram(): AllocationRecipient|null {
    if (!this.isTransplantDetailsPopupApplicable) return null;

    // Check for Primary / Accepted
    const opoProgramEntries = this.manuallyAddedOutOfProvinceEntries || [];
    const filtered = opoProgramEntries.filter((entry: AllocationRecipient) => {
      return entry.offer && entry.offer.offer_type_code == AllocationOfferTypeValues.Primary && entry.offer.response_code == AllocationOfferResponseCodeValues.Accept;
    });
    if (filtered.length === 0) return null;

    // Here we assume there will only be one
    return filtered[0] || null;
  }

  // Does OPO Program have accepted primary offer?
  get hasAcceptedPrimaryOfferToOPOProgram(): boolean {
    return !!this.acceptedPrimaryOfferToOPOProgram;
  }

  // Are we ready to use the OPO Transplant Details feature?
  get isOPOTransplantDetailsEnabled(): boolean {
    if (!this.allocation || !this.hasAcceptedPrimaryOfferToOPOProgram) return false;

    return ALLOCATION_STATES_TRANSPLANT_DETAILS_ENABLED.includes(this.allocation.state);
  }

  // How many columns are visible in the Allocation List?
  get numColumns(): number {
    if (!this.columnsByOrgan) return 1;

    return this.columnsByOrgan.length + 1;
  }

  // Open the OPO Transplant Details popup
  private openOPOTransplantDetailsPopup(): void {
    const opoTransplantDetailsModal = this.$refs.opoTransplantDetailsModal as OpoTransplantDetailsModal;
    if (opoTransplantDetailsModal) opoTransplantDetailsModal.openModal();
  }

  // Bubble up a request to the view that the Donor data needs reloaded
  private reload(): void {
    this.$emit('reload');
  }
}
