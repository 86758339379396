
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { OrganCodeValue, PancreasTypeCodeValue } from '@/store/lookups/types';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors, JourneyDurations } from '@/store/recipientJourney/types';
import TextInput from "@/components/shared/TextInput.vue";
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { PancreasWholeDetails } from "@/store/organSpecificDetails/types";
import { Recipient } from "@/store/recipients/types";
import { ObjectId } from "@/store/types";

export interface WaitlistSummaryState {
  listDate: string|null;
  dialysisDate: string|null;
  hsh: string;
}

// NOTE: the ctr_wait_time (days) number should NOT be displayed on journey page (see TPGLI-5939)
export interface WaitlistDurations {
  on_wait_time_date: string|null;
  ctr_wait_time_date: string|null;
}

@Component({
  components: {
    DateInput,
    TextInput,
    CheckboxInput,
    SubSection,
  },
})
export default class WaitlistSummary extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;
  @State(state => state.journeyState.journeyDurations) journeyDurations!: JourneyDurations;

  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('showIposForJourney', { namespace: 'journeyState' }) private showIposForJourney!: boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) isWaitTimeLoaded!: boolean;

  // Organ code from top-level journey organ_code or from the route params
  get organCode(): number {
    if (this.newJourney) return Number(this.$route.params.organ_code);
    return this.journey?.organ_code ? this.journey.organ_code : 0;
  }

  /*
  * Whether or not to show fields associated with Kidney organ
  *  Display dialysis field on the following scenarios:
  *  - Pancreas Whole Journey PAK.
  *  - Pancreas Whole Journey PRT after Kidney Retransplant  
  *  - Kidney/Pancreas cluster.
  *
  * @returns {boolean} true if Dialysis field should show
  */
  get showKidneyFields(): boolean {
    return this.organCode === OrganCodeValue.Kidney || 
           this.pancreasType === PancreasTypeCodeValue.PAK ||
           this.isPancreasRetransplantAfterPAK ||
           this.isKidneyPancreasCluster;
  }

  /*
  * Returns if this current journey is a pancreas whole journey and if it's having a kidney journey related
  * @returns {boolean} true if the current journey is a Pancreas whole in a KP cluster
  */
  get isKidneyPancreasCluster(): boolean {
    return this.organCode === OrganCodeValue.PancreasWhole && (this.journey?.related_journeys || []).length > 0  && !!this.kidneyJourneyForPancreas;
  }

  /*
  * Returns current kidney related journey only if the journey is a pancreas whole and has related journeys.
  * part of a KP cluster.
  * @returns {RecipientJourney | undefined} current kidney journey if exists.
  */
  get kidneyJourneyForPancreas(): RecipientJourney | undefined {
    if((this.journey?.related_journeys || []).length > 0){
      const relatedJourneys: Array<ObjectId|string> = this.journey?.related_journeys ? this.journey.related_journeys : [];
      const relatedJourneysIds = relatedJourneys.map((journeyId: ObjectId|string) => (typeof journeyId === 'string') ? journeyId : journeyId.$oid);
      
      return this.recipient.journeys?.find((journey: RecipientJourney) => {
        return journey.organ_code === OrganCodeValue.Kidney && relatedJourneysIds.includes(journey._id?.$oid || "");
      });
    }
  }

  /*
  * Returns if this current journey is a pancreas whole retransplant after a PAK
  *
  * @returns {boolean} true if the current journey is a Pancreas whole PRT after a PAK
  */
  get isPancreasRetransplantAfterPAK(): boolean {
    if (this.organCode === OrganCodeValue.PancreasWhole){
      return this.pancreasType === PancreasTypeCodeValue.PRT && !!this.pancreasPAKFromPRTJourney;
    }

    return false;
  }

  /*
  * Gets the PAK journey associated to this journey only if there's a retransplant journey associated to this one
  *
  * @returns {RecipientJourney | undefined} PAK Recipient journey for the current PRT Pancreas Journey.
  */
  get pancreasPAKFromPRTJourney(): RecipientJourney | undefined {
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    
    const pakJourney = this.recipient.journeys?.find((journey: RecipientJourney) => {
      const organSpecificDetails: PancreasWholeDetails|undefined = journey?.organ_specific_details;

      return journey.organ_code === OrganCodeValue.PancreasWhole && 
             journey._id?.$oid == waitlistAttributes.factors?.retransplant_prior_journey_id?.$oid &&
             organSpecificDetails?.pancreas_type === PancreasTypeCodeValue.PAK;
    });

    return pakJourney;
  }

  /*
  * Gets the pancreas type if this journey is a pancreas whole journey.
  *
  * @returns {string | null | undefined} Pancreas type for the current journey
  */
  get pancreasType(): string | null | undefined{
    let pancreas_type;
    if (this.journey.organ_code === OrganCodeValue.PancreasWhole){
      const organSpecificDetails: PancreasWholeDetails|undefined = this.journey?.organ_specific_details;
      pancreas_type = organSpecificDetails?.pancreas_type;
    }
    return pancreas_type;
  }

  // Initialize state based on Recipient Journey data
  private mounted(): void {
    const payload = { recipientId: this.recipientId, journeyId: this.journeyId };
    this.$store.dispatch('journeyState/loadJourneyDurations', payload).then(() => {
      this.initializeForm();
      this.$emit('loaded', 'waitlistSummary');
    });
  }

  /**
   * Initialize form state based on latest Recipient Journey data
   *
   * Note: assumes that the 'waitlistSection' page key has been already been set in the
   * _WaitlistSection card-section component before this component sets its nested state
   */
  private initializeForm(): void {
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      componentKey: 'summary',
      value: this.buildWaitlistSummaryState()
    });
  }

  /**
   * Build state for the Waitlist Summary area based on selected journey
   * 
   * @returns {WaitlistSummaryState} form state for Waitlist Summary
   */
  private buildWaitlistSummaryState(): WaitlistSummaryState {
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    const waitlistFactors = waitlistAttributes?.factors || {};
    const result = {
      listDate: this.parseDateUi(waitlistFactors.listing_date) || null,
      dialysisDate: this.dialysisDate,
      hsh: this.journey?.recipient_programs?.hsx_eligible ? this.$t('yes').toString() : this.$t('no').toString(),
    };
    return result;
  }

  /*
  * Gets the current dialysis date depending on the condition of the current journey.
  * - for PRT after a PAK the dialysis date is the dialysis date of the kidney journey associated with the PAK journey.
  * - for pancreas cluster, the dialysis date is the one from the active Kidney journey associated to this journey.
  * - for PAK journeys, the dialysis date is the one of the kidney associated to this journey.
  * - else will take the current waitlist factors dialysis date of the journey.
  * @returns {string | null} dialysis date
  */
  get dialysisDate(): string|null{
    const waitlistAttributes = this.journey?.stage_attributes?.waitlist || {};
    const waitlistFactors = waitlistAttributes?.factors || {};

    let kidneyJourney;
    let previousJourneyWaitlistFactors;
  
    // if is PRT after PAK the dialysis date should be the one from the kidney associated with the PAK
    if(this.journey.organ_code === OrganCodeValue.PancreasWhole && this.isPancreasRetransplantAfterPAK){
      return this.dialysisDatePRTAfterPAK;
    }
    // if it's pancreas cluster, the dialysis date should be the kidney related journey
    else if(this.isKidneyPancreasCluster){
      previousJourneyWaitlistFactors = this.kidneyJourneyForPancreas?.stage_attributes?.waitlist?.factors || {};
      
      return this.parseDateUi(previousJourneyWaitlistFactors.dialysis_start_date) || null;
    }
    // if is PAK the dialysis date should be the one from the kidney associated with this journey
    else if (this.journey.organ_code === OrganCodeValue.PancreasWhole && this.pancreasType === PancreasTypeCodeValue.PAK){
      kidneyJourney = this.recipient.journeys?.find((journey: RecipientJourney) => {
        return journey._id?.$oid == waitlistFactors.kidney_prior_journey_id?.$oid;
      });
      previousJourneyWaitlistFactors = kidneyJourney?.stage_attributes?.waitlist?.factors || {};
      
      return this.parseDateUi(previousJourneyWaitlistFactors.dialysis_start_date) || null;
    }
    
    return this.parseDateUi(waitlistFactors.dialysis_start_date) || null;
  }

  /*
  * Gets the dialysis date of the PAK journey associated to a PRT Journey. 
  * this will be the kidney_prior_journey_id of the PAK journey from the current PRT journey.
  * @returns {string | null } Dialysis date of the PAK journey
  */
  get dialysisDatePRTAfterPAK(): string|null {
    const pakJourney = this.pancreasPAKFromPRTJourney;
    const waitlistAttributes: RecipientWaitlistAttributes = pakJourney?.stage_attributes?.waitlist || {};
    const waitlistFactors: RecipientWaitlistFactors = waitlistAttributes?.factors || {};

    let kidneyJourney =  this.recipient.journeys?.find((journey: RecipientJourney) => {
      return journey._id?.$oid == waitlistFactors.kidney_prior_journey_id?.$oid;
    });

    const previousJourneyWaitlistFactors = kidneyJourney?.stage_attributes?.waitlist?.factors || {};
      
    return this.parseDateUi(previousJourneyWaitlistFactors.dialysis_start_date) || null;
  }

  /**
   * Get values derived as part of 'Durations' request e.g. Wait Time Date
   *
   * NOTE: here we are using a getter separate from the 'build state' pattern, because this data
   * is an ad-hoc calculation and the latest values are not available on the recipient journey.
   */
  get waitlistDurations(): WaitlistDurations {
    const ontarioWaitTimeDate = this.parseDateUiFromDateTime(this.journeyDurations?.waitlist?.wait_days_date) || null;
    const canadianTransplantRegistryWaitTimeDate = this.parseDateUiFromDateTime(this.journeyDurations?.waitlist?.ctr_wait_days_date) || null;
    return {
      on_wait_time_date: ontarioWaitTimeDate,
      ctr_wait_time_date: canadianTransplantRegistryWaitTimeDate,
    };
   }

  // Reload component when any the journey's waitlist attributes and/or factors change
  public reload(): void {
    this.initializeForm();
  }
}
