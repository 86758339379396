import { render, staticRenderFns } from "./_AllocationControls.vue?vue&type=template&id=40575aba&"
import script from "./_AllocationControls.vue?vue&type=script&lang=ts&"
export * from "./_AllocationControls.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/allocations/_locales/common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_AllocationControls.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/components/allocations/_locales/_AllocationControls.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_AllocationControls.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "@/components/deceasedDonors/_locales/commonPatientShared.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2FlivingAllocations%2F_AllocationControls.vue"
if (typeof block2 === 'function') block2(component)

export default component.exports