
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { AllocationErrorsMixin } from "@/mixins/allocation-errors-mixin";
import { AllocationUtilsMixin } from "@/mixins/allocation-utils-mixin";
import { Getter, State } from 'vuex-class';
import { TableConfig, CtrErrorContext } from '@/types';
import { SortedTable, SortLink } from 'vue-sorted-table';
import SubSection from '@/components/shared/SubSection.vue';
import { DeceasedDonor } from '@/store/deceasedDonors/types';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Organ, OrganSpecification } from '@/store/lookups/types';
import { AllocationResponse, AllocationRecipient, AllocationResponseAction, AllocationOfferResponseCodeValues, AllocationOfferTypeValues, Allocation, AllocationOffer, AllocationOfferRecipient, RegistrationType, OfferOutcomeContext, MELD_MEDICAL_STATUS, SMC_MEDICAL_STATUS } from '@/store/allocations/types';
import { GenericCodeValue, ObjectId } from '@/store/types';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import OfferIcon from '@/components/allocations/offers/OfferIcon.vue';
import OfferTimers from '@/components/allocations/offers/OfferTimers.vue';
import OfferResponseAccept from '@/components/allocations/offers/OfferResponseAccept.vue';
import OfferResponseDecline from '@/components/allocations/offers/OfferResponseDecline.vue';
import { isMasked } from '@/utils';
import CompareModal from '@/components/deceasedDonors/CompareModal.vue';
import { OrganCodeValue } from '@/store/lookups/types';
import { EP } from '@/api-endpoints';
import TextInput from "@/components/shared/TextInput.vue";
import { sortOptionsByValue } from '@/utils';
import { listedForCodes } from '@/allocation-utils';
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";

interface PageState {
  rowsAll: AllocationResponse[];
  rows: AllocationResponse[];
  editedRows: AllocationResponse[];
  selectAllMatchingRows: boolean;
}

const NO_RESPONSIBLE_PHYSICIAN = '-';

@Component({
  components: {
    OfferIcon,
    SubSection,
    SelectInput,
    OfferTimers,
    SortedTable,
    CompareModal,
    CheckboxInput,
    OfferResponseAccept,
    OfferResponseDecline,
    TextInput
  }
})
export default class AllocationOfferResponse extends mixins(DateUtilsMixin, AllocationErrorsMixin, AllocationUtilsMixin, TranslationUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.deceasedDonors.selected) private donor!: DeceasedDonor;
  @State(state => state.pageState.currentPage.allocationOfferResponses) editState!: PageState;
  @State(state => state.allocations.isLoadingAllocation) private isLoadingAllocation!: boolean;
  @State(state => state.allocations.isRespondingOffer) private isRespondingOffer!: boolean;
  @State(state => state.allocations.isDecliningMultiple) private isDecliningMultiple!: boolean;

  @Getter('clientId', { namespace: 'deceasedDonors' }) private donorId!: string;
  @Getter('selectedAllocation', { namespace: 'allocations' }) private allocation!: Allocation;
  @Getter('allPrimaryBackupOffers', { namespace: 'allocations' }) private allPrimaryBackupOffers!: AllocationRecipient[];
  @Getter('offerResponses', { namespace: 'lookups' }) private offerResponses!: GenericCodeValue[];
  @Getter('responseOptions', { namespace: 'allocations' }) private responseOptions!: (offer: AllocationResponse, offerResponses: GenericCodeValue[]) => GenericCodeValue[];
  @Getter('reasonCategoryOptions', { namespace: 'allocations' }) private reasonCategoryOptions!: (offer: AllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('reasonOptions', { namespace: 'allocations' }) private reasonOptions!: (offer: AllocationResponse, offerResponses: GenericCodeValue[], organCode: string) => GenericCodeValue[];
  @Getter('disableResponseOptions', { namespace: 'allocations' }) private disableResponseOptions!: (offer: AllocationResponse) => boolean;
  @Getter('disableResponseCategoryOptions', { namespace: 'allocations' }) private disableResponseCategoryOptions!: (offer: AllocationResponse) => boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('responsiblePhysiciansByHospitalAndOrgan', { namespace: 'responsiblePhysicians' }) private responsiblePhysicianOptions!: (byHospitalId: string, byOrganCode: string) => GenericCodeValue[];
  @Getter('recipients', { namespace: 'allocations' }) private recipients!: AllocationRecipient[];
  @Getter('isTransplantCoordinator', { namespace: 'users' }) private isTransplantCoordinator!: boolean;
  @Getter('getUsersTransplantPrograms', { namespace: 'users' }) private usersTransplantPrograms!: string[];
  @Getter('clusterOrganCodeDisplayValue', { namespace: 'utilities' }) private clusterOrganCodeDisplayValue!: (organCode: number|null, clusterOrganCode?: string|null) => string;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('getOrganSpecificationName', { namespace: 'lookups' }) getOrganSpecificationName!: (organCode?: number|null, organSpecificationCode?: number|null) => string;
  @Getter('parseCtrErrors', { namespace: 'allocations' }) private parseCtrErrors!: (actions: any[]) => CtrErrorContext[];
  @Getter('isSurgicalUser', { namespace: 'users' }) private isSurgicalUser!: boolean;
  @Getter('getUserResponsiblephysicianId', { namespace: 'users' }) private responsiblephysicianId!: ObjectId;
  @Getter('showIposForAllocation', { namespace: 'allocations' }) private showIposForAllocation!: boolean;
  @Getter('determineHshValue', { namespace: 'allocations' }) determineHshValue!: (recipient?: AllocationRecipient) => string;

  @Prop({ default: false }) combined!: boolean;

  public lookupsToLoad = ['offer_responses', 'exceptional_distribution_acceptance_reasons', 'donor_exceptional_distribution'];
  public allocationResponseErrorMessage: string|undefined = '';
  public searchParams: { [key: string]: { value: string; exact: boolean; }} = {};

  public selectAllMatchingRows(checked: boolean): void {
    if (checked) {
      this.editState.rows.map((row: AllocationResponse) => {
        // make offer: can do multiple offers regardless of sequence for backup and no-offer
        // check if transplant coordinator & primary/backup offer or just primary/backup offer
        const selectable = this.showCombined ? this.sameTransplantProgram(row) && this.isPrimaryOrBackup(row) : this.isPrimaryOrBackup(row);
        // check no response recorded
        row.selected = (selectable && row.offerType && row.responseDateTime == '-' && row.responseBy == '-') ? true : false;
      });
    } else {
      this.editState.rows.map((row: AllocationResponse) => {
        row.selected = false;
      });
    }
  }

  // show combined view (designed for transplant coordinator or surgical recovery coordinator)
  get showCombined(): boolean {
    return this.combined;
  }

  /**
   * Return a list of AllocationRecipients based on the user.
   *
   * If the user is a Transplant Coordinator the listing should show all
   * AllocationRecipients, regardless of any offer made.  All other users
   * should only see Recipients who have a primary or backup offer.
   *
   * @returns {AllocationRecipient[]} list of Allocation Recipients
   */
  get recipientListing(): AllocationRecipient[] {
    if (this.showCombined) return this.recipients || [];
    return this.allPrimaryBackupOffers || [];
  }

  /**
   * Returns responsible sorted physician options
   *
   * @param byHospitalId the hospital id
   * @param byOrganCode the organ code
   * @returns {GenericCodeValue[]} sorted responsible physicians.
   */
  private sortedResponsiblePhysicianOptions(byHospitalId: string, byOrganCode: string): GenericCodeValue[] {
    return sortOptionsByValue(this.responsiblePhysicianOptions(byHospitalId, byOrganCode));
  }

  /**
   * Checks to see if listed for contains kidney and pancreas
   *
   * @param listed_for_codes  all the listed_for organ codes
   * @returns {boolean} true / false
   */
  listedForIncludesKidneyAndPancreasCombination(listed_for_codes: string[]): boolean {
    // if no listed_for codes, return false
    if (!listed_for_codes || Array.isArray(listed_for_codes) && listed_for_codes.length <= 1) return false;
    // if kidney & pancreas whole return true
    const organs: string[] = [];
    listed_for_codes.map((item: string) => {
      // separate organs, if we find one clustered split it so we have one array containing separate organs
      if (item.includes('/')) {
        const separated_organs = item.split('/');
        separated_organs.map((single_organ: string) => {
          organs.push(single_organ);
        });
      } else {
        organs.push(item);
      }
    });
    // deduplicate the array
    const unique_organs = [...new Set(organs)];

    // use that to see if we have a kidney & pancreas
    // ...regardless of whether it's made up of [kidney/lung, pancreas] or [kidney/pancreas, lung] or [kidney, pancreas, lung/liver]
    return unique_organs.includes(OrganCodeValue.Kidney.toString()) && unique_organs.includes(OrganCodeValue.PancreasWhole.toString());
  }

  /**
   * Checks to see if organ listed should be highlighted
   *
   * @param organ_code         the 'organ' row's organ code when it's a single organ
   * @param cluster_organ_code the 'organ' row's organ code when it's a cluster
   * @param listed_for_code    this particular listed_for organ code
   * @param listed_for_codes   all the listed_for organ codes
   * @returns {boolean} true if should be highlighted
   */
  highlightOrgan(organ_code: string, cluster_organ_code: string, listed_for_code: string, listed_for_codes: string[]): boolean {
    const real_organ_code = cluster_organ_code ? cluster_organ_code : organ_code;
    if (!listed_for_code) { return false; }
    if (this.allocation.organ_code != OrganCodeValue.Kidney) { return false; }
    if (real_organ_code == '3/6') { return false; }
    const listedForKidneyPancreas = this.listedForIncludesKidneyAndPancreasCombination(listed_for_codes);
    return real_organ_code == listed_for_code && listedForKidneyPancreas;
  }

  /**
   * Return true if we're allowed to POST with this user
   *
   * @returns {boolean} true if we have POST access
   */
  get showControls(): boolean {
    return this.checkAllowed(EP.deceasedDonors.allocations.offers.respond, "POST");
  }

  /**
   * Get a string representation the organ_code
   *
   * @returns {string} organ_code param as a string
   */
  get organCode(): string {
    return this.$route.params.organ_code ? this.$route.params.organ_code.toString() : '';
  }

  /**
   * Returns an array of options for Organ Specification
   *
   * Fetches the organ specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} options for organ specification
   */
  get organSpecificationLookup(): OrganSpecification[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on organCode
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code.toString() === this.organCode.toString();
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Fetch appropriate options sub table
    const organSpecifications: OrganSpecification[] = organLookupEntry?.sub_tables?.organ_specifications || [];
    const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
      return !!organSpec.offer;
    });
    return offerOrganSpec;
  }

  get allocationOrganCode(): number|null {
    return this.allocation.organ_code || null;
  }

  /**
   * Return an array of the table headers by Organ Code
   *
   * @returns {string[]} array of table headers for offer response table
   */
  get tableHeaders(): string[] {
    let columns = ['selected', 'accept_exd', 'offerType', 'organ_spec_offered', 'rank', 'client_id', 
      'lastName', 'hospital_abbreviation', 'organ', 'lung_specification', 'offerDateTime', 'offeredBy', 
      'responseCode', 'responseCategoryCode', 'responseReasonCode', 
      'responseDateTime', 'responsiblePhysician', 'responseBy',
      'medical_status', 'secondary_medical_status', 'hsp', 'hsh', 'allocation_points', 
      'mpe_score', 'abo', 'actual_tlc', 'predicted_tlc', 'sex', 'age', 'height', 'weight',
      'cpra', 'adjusted_organ_cpra', 'unadjusted_cpra', 'previous_transplant', 'crossed_antigens', 'recipientStatus'];

    switch(this.allocationOrganCode) {
      case OrganCodeValue.SmallBowel:
      case OrganCodeValue.VCA:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        break;
      case OrganCodeValue.Liver:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "cpra");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        break;
      case OrganCodeValue.PancreasWhole:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        break;
      case OrganCodeValue.PancreasIslets:
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        break;
      case OrganCodeValue.Kidney:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        if (this.ctrIposKidney) {
          // hide for ipos kidney
          columns = columns.filter((item: string) => item !== "cpra");
        } else {
          // hide for non ipos kidney
          columns = columns.filter((item: string) => item !== "unadjusted_cpra");
          columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
          columns = columns.filter((item: string) => item !== "crossed_antigens");
        }
        break;
      case OrganCodeValue.Lung:
        columns = columns.filter((item: string) => item !== "secondary_medical_status");
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        break;
      case OrganCodeValue.Heart:
        columns = columns.filter((item: string) => item !== "hsp");
        columns = columns.filter((item: string) => item !== "allocation_points");
        columns = columns.filter((item: string) => item !== "mpe_score");
        columns = columns.filter((item: string) => item !== "organ_spec_offered");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        if (this.showIposForAllocation) {
          // hide for ipos heart
          columns = columns.filter((item: string) => item !== "secondary_medical_status");
        } else {
          // hide for non-ipos heart
           columns = columns.filter((item: string) => item !== "hsh");
       }
        break;
      default:
        // show all
        columns = columns.filter((item: string) => item !== "hsh");
        columns = columns.filter((item: string) => item !== "unadjusted_cpra");
        columns = columns.filter((item: string) => item !== "adjusted_organ_cpra");
        columns = columns.filter((item: string) => item !== "crossed_antigens");
        columns = columns.filter((item: string) => item !== "lung_specification");
        columns = columns.filter((item: string) => item !== "actual_tlc");
        columns = columns.filter((item: string) => item !== "predicted_tlc");
        columns = columns.filter((item: string) => item !== "previous_transplant");
        break;
    }

    if (!this.showAcceptExdColumn) {
      // remove accept_exd from column if no offer response is applicable for accept Exd button
      columns = columns.filter((item: string) => item !== "accept_exd");
    }

    return columns;
  }

  /** Filter offers by search params
   *
   * @param event input event object
   * @param field field name
   * @param exact does the value need to match exactly (no lowerCase match)
   */
    public updateFilters(event: any, field: string, exact: boolean) {
    this.searchParams[field] = { value: event, exact: exact };

    let records = this.editState.rowsAll;
    Object.keys(this.searchParams).map((key:string) => {
      records = this.searchAllocationsBy(records, key, this.searchParams[key].value, this.searchParams[key].exact);
    });
  }

  /**
   * Return if we have any rows selected
   *
   * @returns {boolean} true if any rows are selected
   */
  get checkForSelectedRows(): boolean {
    return this.getSelectedRows.length > 0 ? true : false;
  }

  /**
   * Return selected rows
   *
   * @returns {AllocationResponse[]} all selected rows
   */
  get getSelectedRows(): AllocationResponse[] {
    return this.editState.rows.filter((row: AllocationResponse) => row.selected === true) || [];
  }

  /**
   * Return if we skipped any rows
   *
   * Offer responses need to happen in order, this will let us know
   * if any offers were skipped before attempting to respond.
   *
   * @returns {boolean} true if they skipped an offer
   */
  get checkForSkippedResponses() {
    const selectedRows = this.getSelectedRows;
    const selectedProgram = selectedRows[0].program;
    const selectedOrganCode = selectedRows[0].offerOrganCode;
    const skippedRows = this.editState.rows.filter((row: AllocationResponse) => {
      // DIAG: We need to check offerOrganCode because Kidney allocations will contain duplicate recipients (listed for Pancreas and Kidney).
      // The extra record here can make the count seem like we've skipped a row but you can't response to offers of different organ codes at the same time.
      return (!row.selected && row.offerType != undefined) &&
        this.responseIsUndefined(row) &&
        (row.program === selectedProgram) &&
        (selectedOrganCode === row.offerOrganCode);
    });
    const firstSkipped = skippedRows[0] ? skippedRows[0].effective_rank : undefined;
    const lastSelected = selectedRows[selectedRows.length - 1] ? selectedRows[selectedRows.length - 1].effective_rank : undefined;
    if (firstSkipped && lastSelected) {
      return firstSkipped < lastSelected ? true : false;
    } else {
      return false;
    }
  }

  // Check if a value is masked
  public checkMasked(value: string|undefined): boolean {
    if (value == null) return false;
    return isMasked(value);
  }


  // Initialize the form before the page mounts
  public mounted(): void {
    this.$store.dispatch('responsiblePhysicians/loadAllResponsiblePhysicians')
    .then(() => {
      this.initializeOfferResponse();
    });
  }

  /**
   * Watch for changes to the allocations
   *
   * @listens allocation#changed
   */
  @Watch('allocation', { immediate: true, deep: true })
  public initializeOfferResponse() {
    this.$store.commit('pageState/set', {
      pageKey: 'allocationOfferResponses',
      value: {
        rowsAll: this.buildOfferRows(this.recipientListing), // used for searching
        rows: this.buildOfferRows(this.recipientListing), // used for selection, altered
        selectAllMatchingRows: false,
        programOptions: this.getHospitalPrograms(this.recipientListing)
      }
    });
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens allocationOfferResponses#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'allocationOfferResponses');
  }

  // Update the checkbox in the select column
  public checkRow(event: any, row: any): void {
    if (row && row.effective_rank) {
      if (event.target.checked) {
        this.editState.rows.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = true;
          }
        });
      } else {
        this.editState.rows.map((item: any) => {
          if (item.effective_rank === row.effective_rank) {
            item.selected = false;
          }
        });
      }
    }

    if (this.hasMultipleProgramsInSelection) {
      this.allocationResponseErrorMessage = this.$t("allocation_multiple_programs").toString();
    } else {
      this.allocationResponseErrorMessage = "";
    }
  }

  get getSelectedRecords(): any[] {
    if (!this.editState.rows) return [];
    return this.editState.rows.filter((item: any) => {
      return item.selected == true;
    });    
  }

  /**
   * Checks selected offers for backup offers with no response code.
   * If user has selected offers from different programs return true.
   *
   * @returns {boolean} true if selected offers from multiple programs
   */
  get hasMultipleProgramsInSelection(): boolean {
    const programs: any[] = [];
    this.getSelectedRecords.map((record: any) => {
      if (this.isPrimaryOrBackup(record)) {
        programs.push(record.hospital_abbreviation);
      }
    });
    // deduplicate program array, if more than one kind, return true
    return [...new Set(programs)].length > 1;
  }


  // Update the respone for a given row
  public updateRow(event: string, idx: number, key: string): void {
    const offerType = this.editState.rows[idx].offerType;
    if (offerType == AllocationOfferResponseCodeValues.Accept) {
      Vue.set(this.editState.rows[idx], 'responseCategoryCode', undefined);
      Vue.set(this.editState.rows[idx], 'responseReasonCode', undefined);
    }
    Vue.set(this.editState.rows[idx], key, event);
  }

  // Return filtered offer_responses: Withdraw and Cancel
  public clearValues(idx: number, keys: string[]): void {
    keys.forEach((k: string) => {
      Vue.set(this.editState.rows[idx], k, null);
    });
  }

  // Return a row given an index
  public rowByIdx(idx: number): AllocationResponse {
    return this.editState.rows[idx];
  }

  // Styling for cells
  public getCellStyle(row: AllocationResponse): string {
    let style = [];
    switch(row.responseCode) {
      case AllocationOfferResponseCodeValues.Accept:
        // primary
        style.push("response-accepted");
        break;
      case AllocationOfferResponseCodeValues.AcceptWithCondition:
        // primary
        style.push("response-accepted");
        break;
      case AllocationOfferResponseCodeValues.Decline:
        // decline
        style.push("response-declined");
        break;
      default:
        break;
    }
    return style.join(" ");
  }

  // Styling for rows
  public getRowStyle(row: AllocationResponse): string {
    let style = [];
    const recipient = this.recipientListing.find((item: AllocationRecipient) => {
      return item.effective_rank === row.effective_rank;
    });
    switch(row.offerType) {
      case AllocationOfferTypeValues.Primary:
        // primary
        style.push("offer-row-primary");
        break;
      case AllocationOfferTypeValues.Backup:
        // backup
        style.push("offer-row-backup");
        break;
      case AllocationOfferTypeValues.NoOffer:
        // no offer (will also have to provide reason_category & reason_code)
        style.push("offer-row-no-offer");
        break;
      default:
        // null = no offer
        style.push("offer-row-unoffered");
        break;
    }
    // offer responses lookup
    if (recipient && recipient.offer?.response_code) {
      switch(recipient?.offer?.response_code) {
        case AllocationOfferResponseCodeValues.Accept:
          style.push("row-response-accepted");
          break;
        case AllocationOfferResponseCodeValues.AcceptWithCondition:
          style.push("row-response-accepted-condition");
          break;
        case AllocationOfferResponseCodeValues.Cancel:
        case AllocationOfferResponseCodeValues.Decline:
        case AllocationOfferResponseCodeValues.Withdraw:
          style.push("row-response-declined");
          break;
        default:
          style.push();
          break;
      }
    }
    if (row.hsp === "HSP") style.push("hsp-row");
    return style.join(" ");
  }

  /**
   * Return value of the option using the code
   *
   * @returns {string} value of the option
   */
  public lookupOptionValue(options: GenericCodeValue[], code: string): string {
    if (!options || !code) return '-';
    const option = options.find((item: GenericCodeValue) => {
      return item.code == code;
    });
    return option? option.value : '-';
  }

  /**
   * Return true if the logged in user is from the same Transplant Program
   *
   * NOTE: This impacts whether or not we show the 'Select' checkboxes in the combined
   * Transplant Program View, and must be based on short codes e.g. "TGH", NOT the longer
   * codes e.g. "UHN-TGH". This is because the string will be compared against the program
   * prefix in role names, which are always the shorter codes (TPGLI-6561).
   *
   * @returns {boolean} true if it's the same transplant program
   */
  public sameTransplantProgram(row: AllocationResponse): boolean {
    if (!row) return false;
    const transplantProgram = row.program ? row.program.toLowerCase() : '';
    return this.usersTransplantPrograms.includes(transplantProgram);
  }

  /**
   * Return true if the row has an offer type of No Offer
   *
   * @returns {boolean} true if no offer
   */
  public isNoOffer(row: AllocationResponse): boolean {
    return row?.offerType === AllocationOfferTypeValues.NoOffer;
  }

  /**
   * Return true if the row has an offer type of No Offer
   *
   * @returns {boolean} true if no offer
   */
  public isPrimaryOrBackup(row: AllocationResponse): boolean {
    return row?.offerType === AllocationOfferTypeValues.Primary || row?.offerType === AllocationOfferTypeValues.Backup;
  }

  /**
   * Return true if the row has a response of Cancel or Withdraw
   *
   * @returns {boolean} true if Cancel or Withdraw
   */
  public isCancelOrWithdraw(row: AllocationResponse): boolean {
    const recipientOffer = this.recipientOffer(`${row._id}`);
    // Check the response code of the original offer not the one we're about to make
    if (recipientOffer?.response_code === AllocationOfferResponseCodeValues.Cancel
        || recipientOffer?.response_code === AllocationOfferResponseCodeValues.Withdraw) {
        return true;
    }
    return false;
  }

  /**
   * Retrun true if the row is respondable
   *
   * @returns {boolean} true if we should show the checkbox
   */
  public showCheckbox(row: AllocationResponse): boolean {
    if (this.checkMasked(row.lastName)) return false;
    if (row.offerType == null) return false;
    if (this.showCombined) {
      if (!this.sameTransplantProgram(row)) return false;
      if (row.offerType === AllocationOfferTypeValues.NoOffer) return false;
      const recipientOffer = this.recipientOffer(`${row._id}`);
      // Check the response code of the original offer not the one we're about to make
      if (recipientOffer?.response_code === AllocationOfferResponseCodeValues.Cancel) return false;
      if (recipientOffer?.response_code === AllocationOfferResponseCodeValues.Withdraw) return false;
    }
    return true;
  }

  // PRIVATE

  // Return the recipient offer
  public recipientOffer(recipientId: string): AllocationOffer|undefined {
    const recipient = this.recipientListing.find((item: AllocationRecipient) => {
      return item._id === recipientId;
    });
    return recipient?.offer;
  }

  /**
   * Search Allocation Recipients and filter the listing
   *
   * This will filter the Allocation Recipients by one column (multi columns searches won't work)
   * based on the the last searched for value.  Searches don't stack
   *
   * @param records records to filter by
   * @param column column we intend to search
   * @param value value we intend to search by
   * @param exact does the value need to match exactly (no lowerCase match)
   */
  private searchAllocationsBy(records:any, column: string, value: string, exact: boolean): any {
    const results = records.filter((item: any) => {
      let props = (value && column) ? [item[column]] : Object.values(item);

      return props.some((prop: any) => {
        // If no value we're defaulting back to showing all values
        if (!value) return true;
        // Flag to determine if we should return this record
        let returnValue = false;
        // Lower case all items if they're an array (this would only be the listed_for column)
        const filteredProp: string[] = Array.isArray(prop) ? (prop.map((item: string) => item.toLowerCase())) : [prop];

        // Are we looking for an exact match?
        if (exact) {
          returnValue = filteredProp[0] === value;
        } else {
          // If we're looking through the listed_for column we need to look at
          // the array, otherwise join all the items and search that.
          if (column === 'listed_for') {
            returnValue = filteredProp.includes(value.toLowerCase());
          } else {
            returnValue = filteredProp.join('').toLowerCase().includes(value.toLowerCase());
          }
        }
        // Return true if we found a matching value
        return returnValue || false;
      });
    });

    Vue.set(this.editState, 'rows', results);
    Vue.set(this.editState, 'selectAllMatchingRows', false); // uncheck 'select all'

    return results || [];
  }

  /**
   * Show the Recipient/Donor comparison pop-up for the specified row
   *
   * @param row recipient entry from offer response table
   */
  private openCompareModal(row: AllocationResponse): void {
    const recipientId = row._id;
    const journeyOrganCode = row.offerOrganCode;
    const rank = row.rank;
    (this.$refs.compareModal as CompareModal).initializeAllocationCompare(recipientId, journeyOrganCode, rank);
  }

  // Sanitize 'Listed For' information
  private listedFor(record: AllocationRecipient): string[] {
    let result: string[] = record.listed_for && record.listed_for.length > 0 ? record.listed_for : [this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code)];

    // Combine the organ listing information for Out-of-Province cluster entry
    if (record.out_of_province && record.registration_type === RegistrationType.Cluster) {
      result = [result.join('/')];
    }
    return result;
  }

  /**
   * Builds row data for the Offer Responses table
   *
   * @returns {AllocationRecipient[]} Allocation Recipients table rows
   */
  private buildOfferRows(openOffers: AllocationRecipient[]): AllocationResponse[] {
    if (openOffers.length <= 0) {
      return [];
    }
    const results: AllocationResponse[] = [];
    openOffers.forEach((record: AllocationRecipient) => {
      const responseCode = record.offer?.response_code;
      const hospital_abbreviation = record.hospital_abbreviation ? record.hospital_abbreviation : record.program;
      const responsiblePhysician = record.offer?.responsible_physician_id ? record.offer?.responsible_physician_id : this.isSurgicalUser ? this.responsiblephysicianId?.$oid : NO_RESPONSIBLE_PHYSICIAN;
      const row: any = {
        selected: false,
        _id: record._id,
        offerType: record.offer?.offer_type_code || undefined,
        organ_spec_offered: record.offer && record.offer?.organ_specification_code ? this.getOrganSpecificationName(this.allocation.organ_code, record.offer?.organ_specification_code) : null,
        rank: !record.added_manually ? record.effective_rank : undefined, // effective_rank is the rank to be disaplyed and used
        effective_rank: record.effective_rank || undefined,
        client_id: record.client_id || undefined,
        lastName: record.last_name || '-',
        hospitalId: record.hospital_id,
        program: record.program || '-',
        hospital_abbreviation: record.hospital_abbreviation || record.program || '-',
        organ: this.clusterOrganCodeDisplayValue(record.organ_code, record.cluster_organ_code),
        cluster_organ_code: record.cluster_organ_code,
        offerOrganCode: record.organ_code,
        listed_for: this.listedFor(record),
        listed_for_codes: listedForCodes(record),
        lung_specification: record.organ_specification_preference?.replace('Lung - ', '') || '-',
        offerDateTime: this.parseDisplayDateTimeUiFromDateTime(record.offer?.datetime_offered) || '-',
        offeredBy: record.offer?.offered_by || '-',
        responseCode: responseCode || undefined,
        responseCategoryCode: record.offer?.response_reason_category_code || null,
        responseReasonCode: record.offer?.response_reason_code || null,
        persistedResponseCode: responseCode || undefined,
        responseExceptionalDistribution: record.offer?.response_exceptional_distribution || null, 
        hsp: record.hsp || '-',
        responseDateTime: this.parseDisplayDateTimeUiFromDateTime(record.offer?.response_date) || '-',
        responsiblePhysician: responsiblePhysician || NO_RESPONSIBLE_PHYSICIAN,
        responseBy: record.offer?.response_by || '-',
        recipientStatus: record.status || '-',
        outOfProvince: record.out_of_province,
        medical_status: (record.medical_status || '-').replaceAll(SMC_MEDICAL_STATUS, MELD_MEDICAL_STATUS),
        secondary_medical_status: record.secondary_medical_status || '-',
        allocation_points: record.allocation_points == null ? '-' : record.allocation_points,
        mpe_score: record.mpe_score || '-',
        abo: record.blood_type || '-',
        actual_tlc: record.actual_total_lung_capacity == null ? '-' : record.actual_total_lung_capacity,
        predicted_tlc: record.predicted_total_lung_capacity == null ? "-" : record.predicted_total_lung_capacity,
        sex: record.sex || '-',
        age: record.age === 0 ? 0 : (record.age || '-'),
        height: record.height || '-',
        weight: record.weight || '-',
        cpra: record.cpra === 0 ? 0 : (record.cpra || '-'),
        adjusted_organ_cpra: record.adjusted_organ_cpra === 0 ? 0 : (record.adjusted_organ_cpra || '-'),
        previous_transplant: record.previous_transplants,
        crossed_antigens: record.crossed_antigens ? record.crossed_antigens : '-',
        /**
         * Prevent user from opening compare modal if we have manually added an Out-of-Province Program
         * Note: this assumes that the presence of both the 'added_manually' and 'out_of_province' flags
         * AS WELL AS the absence of 'last_name' is how we should distinguish the OOP Programs
         */
        disableCompareModal: record.added_manually && record.out_of_province && !record.last_name,
        hsh: this.determineHshValue(record),
      };
      results.push(row);
    });
    return results;
  }

  // Extract patch for API
  private extractAllocationResponsePatch(responses: AllocationResponse[]): AllocationResponseAction[] {
    const result: AllocationResponseAction[] = [];
    // filter response to those that have a responseCode
    const filteredResponses = responses.filter((reponse: AllocationResponse) => {
      return reponse.responseCode != null;
    });
    // build payload for each response
    filteredResponses.forEach((response: AllocationResponse) => {
      let reason_category: number|null = null;
      let reason_code: number|null = null;

      // Ensure null is sent if no responsible physician selected (e.g. Out-of-province entry)
      let responsible_physician_id = (response.responsiblePhysician !== NO_RESPONSIBLE_PHYSICIAN) ? response.responsiblePhysician : null;

      // add reason and category if we're accepting with condition
      if (response.responseCode == AllocationOfferResponseCodeValues.AcceptWithCondition || response.responseCode == AllocationOfferResponseCodeValues.Decline || response.responseCode == AllocationOfferResponseCodeValues.RequestExtension) {
        reason_category = response.responseCategoryCode ? response.responseCategoryCode : null;
        reason_code = response.responseReasonCode ? response.responseReasonCode : null;
      }
      // build response payload
      const filteredResponse = {
        recipient_id: response._id,
        type: response.responseCode,
        reason_category: reason_category || null,
        reason_code: reason_code || null,
        responsible_physician_id: responsible_physician_id || null,
        offer_organ_code: response.offerOrganCode
      };
      result.push(filteredResponse);
    });
    return result;
  }

  private responseIsUndefined(item: any): boolean {
    // A NoOffer response can be skipped
    if (item.offerType === AllocationOfferTypeValues.NoOffer) return false;
    switch(item.responseCode) {
      // These responses can be skipped
      case AllocationOfferResponseCodeValues.Accept:
      case AllocationOfferResponseCodeValues.Cancel:
      case AllocationOfferResponseCodeValues.Withdraw:
      case AllocationOfferResponseCodeValues.RequestExtension:
        return false;
        break;
      // These responses can be skipped if they have a response, category and reason code
      case AllocationOfferResponseCodeValues.AcceptWithCondition:
      case AllocationOfferResponseCodeValues.Decline:
        return item.responseCode === undefined || item.responseCategoryCode == null || item.responseReasonCode === null;
        break;
      // Everything else is mandatory
      default:
        return true;
        break;
    }
  }

  // adjust to handle both patch from extractAllocationResponsePatch
  // and response row as well by using dynamic offer_response_key
  private checkPatchForAcceptedOffers(patch: any, offer_response_key = 'type'): boolean {
    let accepted = false;
    patch.map((item: any) => {
      if (item[offer_response_key] === AllocationOfferResponseCodeValues.Accept) { accepted = true; }
      if (item[offer_response_key] === AllocationOfferResponseCodeValues.AcceptWithCondition) { accepted = true; }
    });
    return accepted;
  }

  get selectRowsIncludeNoResponsiblePhysician(): boolean {
    let physicianSelected = false;
    this.getSelectedRows.map((row: AllocationResponse) => {
      const responsiblePhysician = row.responsiblePhysician || NO_RESPONSIBLE_PHYSICIAN;
      if (responsiblePhysician == NO_RESPONSIBLE_PHYSICIAN && !row.outOfProvince) { physicianSelected = true; }
    });
    return physicianSelected;
  }

  // getter method to check offer responses if AcceptEXD button is applicable
  get showAcceptExdColumn(): boolean {
    // check each offer response and if just one of it is acceptExdApplicable true
    // then show Accept ExD Column should be true
    const isShowAcceptExdColumn = this.editState.rows.some((row: AllocationResponse) => { return this.acceptExdApplicable(row); });
    return isShowAcceptExdColumn;
  }

  // method to check per offer response if it's accept exd applicable
  private acceptExdApplicable(response: any): boolean {
    // if row is actively being edited then acceptExdApplicable is always false
    if (response.selected) return false;
    if (response.responseExceptionalDistribution?.accepted) return false;

    const exceptional_distribution = this.allocation.donor.exceptional_distribution || false;
    const isAccepted = this.checkPatchForAcceptedOffers([response], 'persistedResponseCode');

    return exceptional_distribution && isAccepted;
  }

  private acceptExd(row: any): void {
    const patch = this.extractAllocationResponsePatch([row]);

    const offerResponseAccept = this.$refs.offerResponseAccept as OfferResponseAccept;
    offerResponseAccept.initializeModal(patch, this.organCode);
  }

  // Respond to offers
  private respondOffers(): void {
    if (this.checkForSkippedResponses) {
      this.allocationResponseErrorMessage = this.$t('higher_ranking_not_responded').toString();
    } else if (this.getSelectedRows.filter((item) => this.responseIsUndefined(item)).length > 0 ) {
      this.allocationResponseErrorMessage = this.$t('must_have_valid_response').toString();
    } else if (this.selectRowsIncludeNoResponsiblePhysician) {
      this.allocationResponseErrorMessage = this.$t('must_have_valid_responsible_physician').toString();
    } else {

      // clear error message
      this.allocationResponseErrorMessage = "";

      // build patch from edited rows
      const selectedRows = this.getSelectedRows;

      // if not expedited, accept normally
      const patch = this.extractAllocationResponsePatch(selectedRows);
      const exceptional_distribution = this.allocation.donor.exceptional_distribution || false;

      const isAccepted = this.checkPatchForAcceptedOffers(patch);

      // if exceptional_distribution, open Accept Dialog
      if (exceptional_distribution && isAccepted) {
        const offerResponseAccept = this.$refs.offerResponseAccept as OfferResponseAccept;
        offerResponseAccept.initializeModal(patch, this.organCode);
      } else {
        const payload = {
          clientId: this.donorId,
          organCode: this.organCode,
          allocationId: this.allocation._id,
          responseDetails: patch
        };
        this.$store.commit('allocations/startRespondingOffer');
        this.$store.dispatch('allocations/respondOffer', payload).then((success: any) => {
          this.$store.commit('allocations/stopRespondingOffer');
          // Do we need to show a secondary warning outcome popup?
          if (this.isOutcomeNotificationRequired(success)) this.displayOutcomeNotification(success);
          this.reloadTable();
        }).catch((error: any) => {
          this.allocationResponseErrorMessage = error;
          if(error) {
            const error_message = this.getErrorMessage(error);
            // TODO: TECH_DEBT: 
            // Now we can show the error message only using alert, because the refs are not available until component is completed loading 
            alert(error_message);
            this.reloadTable();
          }
          this.$store.commit('allocations/stopRespondingOffer');
        });
      }
    }
  }

  // Decline multiple
  private declineOffers(): void {
    if (this.checkForSkippedResponses) {
      this.allocationResponseErrorMessage = this.$t('higher_ranking_not_responded').toString();
    } else {
      this.allocationResponseErrorMessage = "";

      // build patch from edited rows
      const selectedRows = this.getSelectedRows;

      // open decline-multiple dialog
      const offerResponseDecline = this.$refs.offerResponseDecline as OfferResponseDecline;
      offerResponseDecline.initializeModal(selectedRows, this.offerResponses, this.organCode);
    }
  }

  // Get all Active Allocations which will reload this component
  private reloadTable(): void {
    this.$store.commit('allocations/startLoading');
    Promise.all([
      this.$store.dispatch('allocations/getAllocations', { clientId: this.donorId, state: 'active' }),
      this.$store.dispatch('allocations/getAllocation', { clientId: this.donorId, organCode: this.organCode, allocationId: this.allocation._id }),
    ]).finally(() => {
      Vue.set(this.editState, 'selectAllMatchingRows', false);
      this.$store.commit('allocations/stopLoading');
    });
  }

  /*
   * Whether or not we need to show the 'outcome notification' popup after saving
   *
   * In general, this is only needed if the offer saved but some sort of secondary
   * issue persists. Usually an error will prevent the offer from saving entirely,
   * but for some situations (e.g. CTR sync error) the offer will be saved to the
   * database as if nothing happened... But there is still an issue that may need
   * user attention, so we close this modal and open another to show a 'warning'.
   *
   * @param response the response payload received from API after posting the offer
   * @returns {boolean} true only if the offer needs an outcome notification
   */
  private isOutcomeNotificationRequired(response: any): boolean {
    // Only show this if we see a CTR sync error in the response
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    return ctrErrors.length > 0;
  }

  // Define what is needed for the outcome modal and emit an event
  private displayOutcomeNotification(response: any): void {
    // Outcome warnings are based on CTR Error IDs e.g. attempting to sync HSP offer to CTR
    const ctrErrors = this.parseCtrErrors(response?.data?.offer?.actions || []);
    const warningMessages = ctrErrors.map((warning: CtrErrorContext): string => {
      const warningKey = `warning.${this.allocationIposProgram}.${warning.ctr_error_id}`;
      return this.$te(warningKey) ? this.translateIPOSContext(warningKey).toString() : warning.ctr_error_message;
    });
    // Fetch CTR workflow instructions if there are any
    const instructionsTemplates = ctrErrors.map((ctrError: CtrErrorContext): string => {
      const instructionsKey = `instructions.${this.allocationIposProgram}.${ctrError.ctr_error_id}`;
      return this.$te(instructionsKey) ? this.$t(instructionsKey).toString() : this.$t('instructions.generic').toString();
    });
    
    const context: OfferOutcomeContext = {
      actionId: 'respond_to_offer',
      ctrErrors,
      warningMessages,
      instructionsTemplates,
    };

    this.$emit('display-outcome-notification', context);
  }

  // Offer outcome notification events bubble up to the view
  private displayOutcomeNotificationFromAcceptModal(context: OfferOutcomeContext) {
    this.$emit('display-outcome-notification', context);
  }

}
