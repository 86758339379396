import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { HlaAntibodyTestKit } from '@/store/lookups/types';
import { LaboratoriesState, Laboratory, HlaTestKitDefault, LAB_CODE_OTHER } from '@/store/laboratories/types';

export const getters: GetterTree<LaboratoriesState, RootState> = {
  hlaLaboratoryByLabCode(state) {
    return (labCode: string): Laboratory|undefined => {
      const hlaLaboratories = state.hla || [];
      const laboratory = hlaLaboratories.find((laboratory: Laboratory) => {
        return laboratory.lab_code === labCode;
      });
      return laboratory;
    };
  },
  /**
   * Gets class-specific 'default antibodies' tested by the specified Laboratory's default HLA Prefill.
   *
   * Antibodies tested by the Prefill are implied to be Acceptable unless they are explicitly categorized as
   * Unacceptable or Indeterminate
   *
   * @returns {string[]} class 1 tested antibodies, or an empty array if there are none
   */
  defaultHlaAcceptableClass1(state, getters, rootState, rootGetters) {
    return (): string[] => {
      // Fetch mostrecentHlaTestingKitClas1Code
      const class1testingKitCode = rootGetters['lookups/mostRecentHlaTestingKitClass1Code'];
      // Fetch actual testing kit from lookups
      const allTestingKits = rootGetters['lookups/hlaTestingKits'] || [];
      const testingKit = allTestingKits.find((testingKit: HlaAntibodyTestKit) => {
        return testingKit.code == class1testingKitCode;
      });
      // Fetch prefill from testing kit
      const antibodies = testingKit ? testingKit.prefill_antibodies : undefined;
      return antibodies || [];
    };
  },
  defaultHlaAcceptableClass2(state, getters, rootState, rootGetters) {
    return (): string[] => {
      // Fetch mostrecentHlaTestingKitClas2Code
      const class2testingKitCode = rootGetters['lookups/mostRecentHlaTestingKitClass2Code'];
      // Fetch actual testing kit from lookups
      const allTestingKits = rootGetters['lookups/hlaTestingKits'] || [];
      const testingKit = allTestingKits.find((testingKit: HlaAntibodyTestKit) => {
        return testingKit.code == class2testingKitCode;
      });
      // Fetch prefill from testing kit
      const antibodies = testingKit ? testingKit.prefill_antibodies : undefined;
      return antibodies || [];
    };
  },

  laboratoryOptions(state) {
    return [
      ...state.ser,
      ...[{ code: LAB_CODE_OTHER, name: LAB_CODE_OTHER, lab_code: LAB_CODE_OTHER, other_selected: true }]
    ];
  }
};
