
import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
import {UrlUtilsMixin} from "@/mixins/url-utils-mixin";
import {Getter} from "vuex-class";
import {EP} from "@/api-endpoints";

const DEFAULT_SELECTED_SEARCH_OPTION = 'recipients';

@Component
export default class NavSearch extends mixins(UrlUtilsMixin) {

  @Getter('checkAllowed', { namespace: 'users' } ) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter('canAccessOopRecipients', { namespace: 'users' }) private canAccessOopRecipients!: boolean;
  selectedSearchOption = DEFAULT_SELECTED_SEARCH_OPTION;
  searchTerm = '';

  get availableSearchCategories() {
    const availableCategories: any = {};
    if(this.checkAllowed(EP.deceasedDonors.create)) {
      availableCategories.donors = this.$t('donor_records');
    }
    if(this.checkAllowed(EP.living_donors.create)) {
      availableCategories.living_donors = this.$t('living_donor_records');
    }
    if(this.checkAllowed(EP.recipients.create)) {
      availableCategories.recipients = this.$t('recipient_records');
    }
    if(this.canAccessOopRecipients) {
      availableCategories.oop_recipients = this.$t('oop_recipient_records');
    }

    return availableCategories;
  }

  get canSearchAnything(): boolean {
    return Object.keys(this.availableSearchCategories).length > 0;
  }

  public mounted() {
    // ATU-596 updates
    // on mount this logic will handle which default value to assign in the search bar dropdown categories
    // availableSearchCategories handles which category a user has access on
    // by default TGLN wants to choose recipient as the selected category
    // in case User doesn't have access to recipient category it'll choose the first category on the list
    this.searchTerm = this.getUrlQueryValue('term') || '';
    const availableSearchCategoriesKeys = Object.keys(this.availableSearchCategories);
    const defaultSearchOption = availableSearchCategoriesKeys.find((key) => { return key == DEFAULT_SELECTED_SEARCH_OPTION; }) || availableSearchCategoriesKeys[0];
    this.selectedSearchOption = this.getUrlQueryValue('type') || defaultSearchOption;
  }

  public performSearch() {
    if (this.getUrlQueryValue('term') === this.searchTerm && this.getUrlQueryValue('type') === this.selectedSearchOption) {
      // if both type and term is the same as the current url don't do anything.
      return;
    }
    this.$router.push({
      name: 'global-search',
      query: {
        type: this.selectedSearchOption,
        term: this.searchTerm
      }
    });
  }
}
